import React, { useMemo } from 'react';
import { DeleteForever, Edit } from '@mui/icons-material';
import { projectActionMenuIconStyles } from '../../../style/constants';
import { IProposal } from '../../../../../shared/models/proposals.interface';
import { useDeleteProposal, useProposals } from '../../../hooks/proposals.hook';
import {
  IKebabMenuInput,
  KebabButtonMenu,
} from '../../SelectMenu/KebabButtonMenu';

interface IProposalKebabMenuInput extends IKebabMenuInput {
  proposal: IProposal;
  onEditName: () => void;
}

const ProposalKebabMenu: React.FC<IProposalKebabMenuInput> = ({
  proposal,
  anchor,
  onOpen,
  onClose,
  onEditName,
}) => {
  const { id } = proposal;
  const deleteProposal = useDeleteProposal();
  const proposals = useProposals();
  const disableDelete = proposals.length < 2;

  const items = useMemo(
    () => [
      {
        id: 'rename',
        label: 'Rename',
        icon: <Edit {...projectActionMenuIconStyles} />,
        onClick: onEditName,
      },
      {
        id: 'delete',
        label: 'Delete proposal',
        icon: <DeleteForever {...projectActionMenuIconStyles} />,
        onClick: async () => {
          await deleteProposal(id);
          onClose?.();
        },
        disabled: disableDelete,
      },
    ],
    [deleteProposal, disableDelete, id, onEditName, onClose],
  );

  return (
    <KebabButtonMenu
      id={id}
      items={items}
      anchor={anchor}
      onOpen={onOpen}
      onClose={onClose}
    />
  );
};

export default ProposalKebabMenu;
