import React, {
  CSSProperties,
  useCallback,
  useState,
  MouseEvent,
  useMemo,
  useEffect,
} from 'react';
import {
  ButtonProps,
  Divider,
  ListItemButton,
  Typography,
  TypographyProps,
} from '@mui/material';
import {
  IElement,
  OneOfPropertyElements,
} from '../../../../../../shared/models/project.interface';
import {
  IElementPropertyOption,
  IElementSelectProperty,
} from '../../../../../../shared/models/element_property.interface';
import { useElementPropertiesUtils } from '../../../../hooks/element-properties.hook';
import { isElementQuantitySelectProperty } from '../../../../../../shared/helpers/element_quantity_helpers';
import { useUpdateQuantity } from '../../../../hooks/quantity-properties.hook';
import { ConcreteType } from '../../../../../../shared/templates/categories/concrete/concrete.model';
import { BoardType } from '../../../../../../shared/templates/categories/gypsum/gypsum.model';
import { InsulationMaterial } from '../../../../../../shared/templates/categories/insulation/insulation.model';
import { WoodType } from '../../../../../../shared/templates/categories/wood/wood.model';
import { isElement } from '../../../../../../shared/helpers/recursive_element_helpers';
import { otherNodonProduct } from '../../../../../../shared/templates/categories/other';
import TextButtonMenu from '../../../SelectMenu/TextButtonMenu';
import { EllipsisText } from '../../../EllipsisText';
import { labelFromValue } from '../../../../../../shared/helpers/element_property_factory_helpers';
import { MENU_ITEM_HEIGHT } from '../../../../../../shared/constants';

import {
  getPropertyCount,
  hasCount,
  getSelectPropertyOptions,
  isElementSbefProperty,
  getCountTooltip,
} from '../../../../../../shared/helpers/element_property_helpers';
import { getSbefDividerItems } from '../../../../../../shared/templates/categories/categories-properties.helpers';
import { NodonTheme } from '../../../../style';

interface ElementPropertySelectProps {
  property: IElementSelectProperty;
  element: OneOfPropertyElements;
}

const ElementPropertySelect: React.FC<ElementPropertySelectProps> = ({
  property,
  element,
}) => {
  const count = getPropertyCount(property, false);
  const isFallback = !hasCount(property);

  const { updateProperty } = useElementPropertiesUtils(element);
  const updateQuantity = useUpdateQuantity();

  const isSbefProperty = isElementSbefProperty(property);
  const isSmallMenuWidth = !isSbefProperty && property.options.length < 20;
  const labelMaxLength = isSmallMenuWidth ? 25 : 40;

  const [anchor, setAnchor] = useState<Element>();
  const [buttonLabel, setButtonLabel] = useState('');
  const [disableDivider, setDisableDivider] = useState(false);

  const options = useMemo(() => getSelectPropertyOptions(property), [property]);

  const menuButtonProps = useMemo<ButtonProps>(() => {
    return {
      sx: {
        color: NodonTheme.typography[isFallback ? 'caption' : 'body2'].color,
      },
    };
  }, [isFallback]);

  const update = useCallback(
    async (
      modified: Partial<IElementSelectProperty>,
    ): Promise<IElement | undefined> => {
      if (isElement(element) && isElementQuantitySelectProperty(property)) {
        await updateQuantity(element, [
          {
            ...modified,
            name: property.name,
          },
        ]);
      } else {
        return updateProperty({ ...property, ...modified }).then(
          ({ element }) => element,
        );
      }
    },
    [updateQuantity, updateProperty, property, element],
  );

  const savePropertyCount = useCallback(
    (value: string) => {
      if (value && value !== count) {
        update({ count: value === 'none' ? undefined : value });
      }
    },
    [count, update],
  );

  const handleItemClick = useCallback(
    ({
      currentTarget: {
        dataset: { item },
      },
    }: MouseEvent<HTMLDivElement>) => {
      if (item) {
        savePropertyCount(item);
        setAnchor(undefined);
      }
    },
    [savePropertyCount],
  );

  const handleCloseMenu = useCallback(() => {
    setAnchor(undefined);
  }, [setAnchor]);

  const handleSearch = useCallback(
    (searchString: string) => {
      setDisableDivider(searchString.length > 0);
    },
    [setDisableDivider],
  );

  const getListItemProps = useCallback(
    (item: IElementPropertyOption): ListItemProps => {
      return {
        item,
        disableDivider,
        selected: item.value === count,
        showTooltip: item.label.length > labelMaxLength,
        indentAfterFirstWord: isSbefProperty,
        onClick: handleItemClick,
      };
    },
    [count, handleItemClick, isSbefProperty, labelMaxLength, disableDivider],
  );

  useEffect(() => {
    setButtonLabel(typeof count === 'string' ? labelFromValue(count) : '');
  }, [count, property.count]);

  return (
    <TextButtonMenu
      items={options}
      anchor={anchor}
      label={buttonLabel}
      buttonProps={menuButtonProps}
      menuWidth={isSmallMenuWidth ? 'small' : 'medium'}
      tooltip={getCountTooltip(property)}
      onOpen={setAnchor}
      onClose={handleCloseMenu}
      onSearch={handleSearch}
    >
      {(filteredItems, virtualizedItemsProps) => {
        if (!virtualizedItemsProps) {
          return filteredItems.map((item) => (
            <ListItem key={item.value} {...getListItemProps(item)} />
          ));
        }

        const { index, style } = virtualizedItemsProps;
        const item = filteredItems[index];

        return !!item && <ListItem style={style} {...getListItemProps(item)} />;
      }}
    </TextButtonMenu>
  );
};

interface ListItemProps {
  /** These styles are needed if virtualization is enabled */
  style?: CSSProperties;
  item: IElementPropertyOption;
  selected: boolean;
  showTooltip?: boolean;
  indentAfterFirstWord?: boolean;
  disableDivider?: boolean;
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
}

const ListItem = ({
  item,
  style,
  selected,
  showTooltip,
  indentAfterFirstWord,
  disableDivider,
  onClick,
}: ListItemProps) => {
  const { value, label } = item;
  const [first, ...rest] = indentAfterFirstWord ? label.split(' ') : [];

  return (
    <>
      {!disableDivider && <DividerItem item={item} style={style} />}
      <ListItemButton
        style={{ ...style, zIndex: 1 }}
        data-item={value}
        onClick={onClick}
        selected={selected}
      >
        <Typography {...listItemTextStyle}>
          <EllipsisText showTooltip={showTooltip}>
            {first || label}
          </EllipsisText>
        </Typography>

        {rest.length > 0 && (
          <Typography {...listItemTextStyle}>
            <EllipsisText showTooltip={showTooltip}>
              {rest.join(' ')}
            </EllipsisText>
          </Typography>
        )}
      </ListItemButton>
    </>
  );
};

interface DividerItemProps {
  /** These styles are needed if virtualization is enabled */
  style?: CSSProperties;
  item: IElementPropertyOption;
}

const DividerItem = ({ style, item }: DividerItemProps) => {
  const isNodonProduct =
    otherNodonProduct && item.value === otherNodonProduct.name;

  const isDividerItem = dividerItems.includes(
    item.value as (typeof dividerItems)[number],
  );

  if (!isNodonProduct && !isDividerItem) {
    return null;
  }
  if (!style) {
    return <Divider />;
  }
  return (
    <Divider
      style={{
        ...style,
        zIndex: 0,
        top: isNodonProduct ? style.top : Number(style.top) - MENU_ITEM_HEIGHT,
      }}
    />
  );
};

const dividerItems: string[] = [
  ConcreteType.Other,
  ConcreteType.BalconyAndStairs,
  ConcreteType.Columns,
  BoardType.CelluloseFibre,
  InsulationMaterial.EPS,
  WoodType.OSB,
  ...getSbefDividerItems(),
];

const listItemTextStyle: TypographyProps = {
  minWidth: 30,
  variant: 'body2',
  display: 'flex',
  alignItems: 'center',
} as const;

export default ElementPropertySelect;
