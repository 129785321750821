import React, {
  FC,
  useCallback,
  useMemo,
  FocusEvent,
  BaseSyntheticEvent,
  useEffect,
} from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mui/material';
import { useForm, Controller, ControllerRenderProps } from 'react-hook-form';
import * as Yup from 'yup';
import { Project } from '../../../shared/models/project.interface';
import SettingsForm from './SettingsForm';
import amplitudeLog from '../amplitude';
import NodonTextField from './NodonTextField';
import ElementClose from './SidePanel/Element/ElementClose';
import { useIsReadonly, useUserIsOwner } from '../hooks/user.hook';
import { useProjectState } from '../store/project';
import { omit } from '../../../shared/helpers/object_helpers';
import { useUIState } from '../store/ui';

export interface ProjectDetailsFormData {
  name: string;
  description: string;
}

interface FormRenderProps<T extends keyof ProjectDetailsFormData> {
  field: ControllerRenderProps<ProjectDetailsFormData, T>;
}

interface ProjectDetailsEditorProps {
  project: Project;
  isNewProject: boolean;
  setIsNewProject: (isNewProject: boolean) => void;
}

const ProjectDetailsEditor: FC<ProjectDetailsEditorProps> = ({
  project,
  isNewProject,
  setIsNewProject,
}) => {
  const { updateProjectDetails, isLoading } = useProjectState(
    'updateProjectDetails',
    'isLoading',
  );
  const { setShowProjectDetailsEditor } = useUIState(
    'setShowProjectDetailsEditor',
  );

  const isReadonly = useIsReadonly();
  const isOwner = useUserIsOwner();

  const schema = Yup.object({
    name: Yup.string().trim().required('Name is required'),
  });

  const defaultValues: ProjectDetailsFormData = useMemo(
    () => ({
      name: project.name,
      description: project.description ?? '',
    }),
    [project],
  );

  const { control, handleSubmit, formState, reset } =
    useForm<ProjectDetailsFormData>({
      defaultValues,
      resolver: yupResolver(schema),
      mode: 'onChange',
      reValidateMode: 'onChange',
    });

  const { errors } = formState;

  const submit = useCallback(
    (event: BaseSyntheticEvent<Record<string, unknown>, any, any>) => {
      handleSubmit(({ name, description }) => {
        updateProjectDetails({
          projectId: project.id,
          data: { name, description },
        });

        name !== project.name
          ? amplitudeLog('Project Name Set', {
              ProjectName: name,
            })
          : amplitudeLog('Project Description Set', {
              ProjectDescription: description,
            });
      })(event);
    },
    [handleSubmit, project, updateProjectDetails],
  );

  const onFocus = useCallback(
    (e: FocusEvent<HTMLInputElement>): void => {
      isNewProject && e.currentTarget.select();
      setIsNewProject(false);
    },
    [isNewProject, setIsNewProject],
  );

  const onBlur = useCallback((e: FocusEvent<HTMLInputElement>): void => {
    e.currentTarget.blur();
  }, []);

  const renderNameInput = useCallback(
    ({ field }: FormRenderProps<'name'>) => (
      <NodonTextField
        {...omit(field, 'ref')}
        fullWidth
        required
        autoFocus
        label="Name"
        error={!!errors.name}
        helperText={errors.name?.message}
        disabled={!isOwner && project.locked}
        inputRef={field.ref}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    ),
    [errors.name, onFocus, onBlur, isOwner, project.locked],
  );

  const renderDescriptionInput = useCallback(
    ({ field }: FormRenderProps<'description'>) => (
      <NodonTextField
        {...omit(field, 'ref')}
        multiline
        minRows={4}
        label="Description"
        error={!!errors.description}
        helperText={errors.description?.message}
        disabled={isReadonly}
        inputRef={field.ref}
      />
    ),
    [errors.description, isReadonly],
  );

  useEffect(() => {
    if (project) {
      reset(defaultValues);
    }
  }, [reset, defaultValues, project]);

  useEffect(() => {
    if (isLoading) {
      setShowProjectDetailsEditor(false);
    }
  }, [isLoading, setShowProjectDetailsEditor]);

  return (
    <Box>
      <ElementClose />
      <SettingsForm formState={formState} submit={submit}>
        <Box display="flex" justifyContent="space-between">
          <Controller control={control} name="name" render={renderNameInput} />
        </Box>
        <Controller
          control={control}
          name="description"
          render={renderDescriptionInput}
        />
      </SettingsForm>
    </Box>
  );
};

export default ProjectDetailsEditor;
