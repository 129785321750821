import React, { useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import {
  IElement,
  IProductElement,
} from '../../../../../../../shared/models/project.interface';
import { useSelectedVersion } from '../../../../../store/ui';
import ExpressionInput from '../../../../ExpressionInput';
import { getExpression } from '../../../../../../../shared/helpers/expression_solving_helpers';
import { selectableUnitHarmonizer } from '../../../../../../../shared/helpers/unit_helpers';
import { useProductsLookup } from '../../../../../store/product';
import { makeStyles } from 'tss-react/mui';
import { EllipsisText } from '../../../../EllipsisText';
import EPDMenu from './EPDMenu';
import { required } from '../../../../../../../shared/helpers/function_helpers';

const PRODUCT_NAME_MAX_LENGTH = 30;

interface ElementChildAutoGeneratedProps {
  parent: IElement;
  productElement: IProductElement;
}

const ElementChildAutoGenerated: React.FC<ElementChildAutoGeneratedProps> = ({
  parent,
  productElement,
}) => {
  const { classes } = useStyles();
  const selectedVersion = required(useSelectedVersion());

  const { count, unit, product_id, generic_product_id } = productElement;
  const genericProductId = generic_product_id ?? product_id;

  const productsLookup = useProductsLookup();

  const genericProduct = useMemo(
    () =>
      selectedVersion.products[genericProductId] ??
      productsLookup[genericProductId],
    [productsLookup, genericProductId, selectedVersion.products],
  );

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <Typography width="60%" variant="body2" className={classes.text}>
          <EllipsisText
            showTooltip={
              genericProduct &&
              genericProduct.name.length > PRODUCT_NAME_MAX_LENGTH
            }
          >
            {genericProduct ? genericProduct.name : 'Product not found'}
          </EllipsisText>
        </Typography>
        <Box width="40%" display="flex" justifyContent="flex-end">
          <ExpressionInput
            id={productElement.id}
            expressionValue={getExpression(count)}
            unit={selectableUnitHarmonizer(unit)}
            disabled
          />
        </Box>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        width="100%"
      >
        <EPDMenu parent={parent} productElement={productElement} />
      </Box>
    </>
  );
};

const useStyles = makeStyles()(({ palette }) => ({
  text: {
    display: 'flex',
    fontSize: 13,
    fontWeight: 500,
  },
  discrete: { color: palette.text.disabled },
}));

export default ElementChildAutoGenerated;
