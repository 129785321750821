import { Tabs, Tab, Theme } from '@mui/material';
import React, { SyntheticEvent, useCallback, useMemo } from 'react';
import { useNavigateTo } from '../hooks/router.hooks';
import { useUIState } from '../store/ui';
import { Page } from '../store/ui/ui.model';
import { makeStyles } from 'tss-react/mui';

const PageNavigation = () => {
  const { selectedPage } = useUIState('selectedPage');
  const { classes } = useStyles();
  const navigateTo = useNavigateTo();

  const tabsClasses = useMemo(
    () => ({
      root: classes.tabs,
      indicator: classes.tabIndicator,
    }),
    [classes.tabs, classes.tabIndicator],
  );

  const selectPage = useCallback(
    (event: SyntheticEvent, page: Page) => {
      event.stopPropagation();
      navigateTo({ page });
    },
    [navigateTo],
  );

  return (
    <Tabs
      textColor="secondary"
      value={selectedPage}
      classes={tabsClasses}
      onChange={selectPage}
    >
      <Tab label="Elements" value="elements" />
      <Tab label="Products" value="products" />
      {selectedPage === 'calculations' && (
        <Tab label="Calculations" value="calculations" />
      )}
    </Tabs>
  );
};

const useStyles = makeStyles()(({ spacing, breakpoints }: Theme) => ({
  tabs: {
    marginLeft: spacing(12),
  },
  tab: {
    [breakpoints.down('lg')]: {
      minWidth: 125,
    },
  },
  tabIndicator: {
    display: 'none',
  },
}));

export default PageNavigation;
