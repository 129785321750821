import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  useEPDIdsAppliedToGenericProduct,
  useProductSwitch,
} from '../../../../../hooks/product-switch.hook';
import {
  IElement,
  IProductElement,
} from '../../../../../../../shared/models/project.interface';
import { required } from '../../../../../../../shared/helpers/function_helpers';
import { openEditProductDialog } from '../../../../../projects/EditProject/EditProductDialog';
import { useProductsLookup } from '../../../../../store/product';
import { useSelectedVersion } from '../../../../../store/ui';
import ProductSelector from '../../../../../projects/EditProject/ProductSelector';
import EPDSelectMenu from './EPDSelectMenu';
import EPDButtonMenu from './EPDButtonMenu';
import { useEPDMenuItems } from './EPDMenuItems';
import {
  Product,
  ProductID,
} from '../../../../../../../shared/models/product.interface';

interface EPDMenuProps {
  parent: IElement;
  productElement: IProductElement;
  variant?: 'button' | 'select';
}

const EPDMenu: React.FC<EPDMenuProps> = ({
  parent,
  productElement,
  variant = 'select',
}) => {
  const { product_id, generic_product_id } = productElement;
  const genericProductId = generic_product_id ?? product_id;

  const selectedVersion = required(useSelectedVersion());
  const productsLookup = useProductsLookup();

  const {
    isProductSelectorOpen,
    openProductSelector,
    closeProductSelector,
    switchProducts,
    clearEPD,
  } = useProductSwitch(productElement);

  const appliedEPD = useMemo(
    () =>
      generic_product_id ? selectedVersion.products[product_id] : undefined,
    [generic_product_id, product_id, selectedVersion.products],
  );

  const localstorageIds = useEPDIdsAppliedToGenericProduct(genericProductId);

  const mappedEPDs = useMemo(() => {
    const epdsMappedInLocalstorage: Product[] = [];

    for (const id of localstorageIds ?? []) {
      const product = productsLookup[id];

      // epds in localstorage might not be in the lookup
      if (product) epdsMappedInLocalstorage.push(product);
    }

    const epdsMappedInDatabase = Object.values(productsLookup).filter(
      ({ generic_id }) => generic_id === genericProductId,
    );

    const unique = new Set([
      ...epdsMappedInLocalstorage,
      ...epdsMappedInDatabase,
    ]);

    return [...unique];
  }, [localstorageIds, genericProductId, productsLookup]);

  /* 
  Id of the EPD item that the kebab sub menu has been opened from. 
  Used to prevent closing the parent menu when the kebab menu is closed.
  */
  const [epdId, setEpdId] = useState('');
  const [selectedEpdId, setSelectedEpdId] = useState('');
  const [isSelectedMenuValue, setIsSelectedMenuValue] = useState(false);

  const handleEPD = useCallback(
    async (editId?: ProductID) => {
      const product = await openEditProductDialog({
        parent: !editId ? parent : undefined,
        product: editId ? productsLookup[editId] : undefined,
      });

      // If not cancelled
      if (product) {
        if (editId && editId !== appliedEPD?.id) {
          // only do a switch if the edited epd is the applied one
          return;
        }
        if (editId) {
          // clear epd to replace the product in version with the edited product (should be handled elsewhere?)
          await clearEPD();
        }
        switchProducts(product.id);
      }
    },
    [appliedEPD?.id, clearEPD, parent, productsLookup, switchProducts],
  );

  const selectEPD = useCallback(
    async (id: string) => {
      // set this to trigger the button menu to close
      setIsSelectedMenuValue(true);

      if (id === 'none') {
        setSelectedEpdId('');
        return clearEPD();
      }
      if (id === 'other') return openProductSelector();
      if (id === 'new') return handleEPD();

      // menuItem is the id of the selected product
      switchProducts(id)
        .then(() => setSelectedEpdId(id))
        .catch(() => setSelectedEpdId(appliedEPD?.id ?? ''));
    },
    [appliedEPD?.id, clearEPD, handleEPD, openProductSelector, switchProducts],
  );

  const handleProductSelectorSave = useCallback(
    (pid: string, gid?: string, isSelectedEPD = false) => {
      if (isSelectedEPD || genericProductId === gid) {
        switchProducts(pid);
        closeProductSelector();
      }
    },
    [closeProductSelector, genericProductId, switchProducts],
  );

  const menuItems = useEPDMenuItems({
    variant,
    epds: mappedEPDs,
    genericProductId,
    selectedEpdId,
    epdId,
    setSelectedEpdId,
    setEpdId,
    selectEPD,
    clearEPD,
    handleEPD,
  });

  useEffect(() => {
    const mappedIds = mappedEPDs.map(({ id }) => id);
    const appliedId = appliedEPD?.id ?? '';

    setSelectedEpdId(mappedIds.includes(appliedId) ? appliedId : '');
  }, [appliedEPD, mappedEPDs]);

  return (
    <>
      {variant === 'select' && (
        <EPDSelectMenu
          items={menuItems}
          epds={mappedEPDs}
          selectedEpdId={selectedEpdId}
          epdKebabId={epdId}
          selectEPD={selectEPD}
        />
      )}

      {variant === 'button' && (
        <EPDButtonMenu
          items={menuItems}
          selectedEPDName={appliedEPD?.name}
          preventClose={!isSelectedMenuValue || !!epdId}
          setIsSelectedMenuValue={setIsSelectedMenuValue}
        />
      )}

      {/* For performance reasone only render when it's open */}
      {isProductSelectorOpen && (
        <ProductSelector
          keepOpen
          open={isProductSelectorOpen}
          parent={parent}
          productId={appliedEPD?.id}
          defaultSource="custom"
          onSave={handleProductSelectorSave}
          onClose={closeProductSelector}
        />
      )}
    </>
  );
};

export default EPDMenu;
