import { MoreVert, MoreHoriz } from '@mui/icons-material';
import { SvgIconProps, IconButton, Tooltip } from '@mui/material';
import React from 'react';

export interface KebabButtonProps {
  tooltipTitle?: string;
  disabled?: boolean;
  svgProps?: SvgIconProps;
  verticalIcon?: boolean;
  onClick: React.MouseEventHandler<HTMLElement>;
}

const KebabButton = ({
  tooltipTitle,
  disabled,
  svgProps,
  verticalIcon,
  onClick,
}: KebabButtonProps) => (
  <Tooltip title={tooltipTitle}>
    <IconButton
      size="small"
      color={disabled ? 'secondary' : 'default'}
      onClick={onClick}
      component="span"
      disabled={disabled}
    >
      {verticalIcon ? (
        <MoreVert {...svgProps} />
      ) : (
        <MoreHoriz color="secondary" {...svgProps} />
      )}
    </IconButton>
  </Tooltip>
);

export default KebabButton;
