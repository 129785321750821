import { useCallback } from 'react';
import { DropType } from '../components/drag-and-drop/Droppable';
import {
  isElementWithGeneratedChildren,
  isGeneratedProductElement,
} from '../../../shared/helpers/element_helpers';
import {
  addElements,
  removeElements,
} from '../../../shared/helpers/project_helpers';
import {
  getChildElements,
  getElementById,
  getParentElement,
  isBuildingVersionElement,
  isOneOfChildElements,
  isParentElement,
  isProductElement,
} from '../../../shared/helpers/recursive_element_helpers';
import {
  IBuildingVersion,
  OneOfChildElements,
  OneOfParentElements,
  Project,
} from '../../../shared/models/project.interface';
import { getProject, useUpdateProject } from '../store/project';
import { getSelectedVersion } from '../store/ui';
import { IInsertSortPlacement } from '../../../shared/models/sort.interface';
import {
  getElementVersionId,
  isElementVersionElement,
} from '../../../shared/helpers/element-version.helpers';
import { isProductCategoryElement } from '../../../shared/helpers/element_category_helpers';
import { getFlattenedSortedElements } from './filter-elements.hook';
import { ItemOrItemId } from '../../../shared/models/type_helpers.interface';
import { omit } from '../../../shared/helpers/object_helpers';

interface IUseMoveElements {
  moveElementsTo: (
    moveIntoId: string,
    moveType: DropType,
    ...elementIds: string[]
  ) => Promise<Project>;
  isMoveElementsAllowed: (
    moveToId: string | undefined,
    moveType?: DropType,
    ...elementIds: string[]
  ) => boolean;
}

export const useMoveElements = (): IUseMoveElements => {
  const updateProject = useUpdateProject();

  const isMoveElementsAllowed = useCallback(
    (
      moveToId: string | undefined,
      moveType?: DropType,
      ...elementIds: string[]
    ) => {
      const selectedVersion = getSelectedVersion();
      const sortedElements = getFlattenedSortedElements();

      if (!selectedVersion || elementIds.length === 0) {
        return false;
      }

      const moveIntoElement = getMoveIntoElement(
        selectedVersion,
        moveToId,
        moveType,
      );
      const moveNextToElement =
        moveType !== 'inside'
          ? getElementById(moveIntoElement, moveToId)
          : undefined;

      // Can't move element before or after a version
      if (
        moveType !== 'inside' &&
        isBuildingVersionElement(moveIntoElement) &&
        moveIntoElement.id === moveToId
      ) {
        return false;
      }

      // Can't move element to a product element
      if (!moveIntoElement || !isParentElement(moveIntoElement)) {
        return false;
      }

      // Can't move element to an element with generated children
      if (
        isElementWithGeneratedChildren(moveIntoElement) ||
        isProductCategoryElement(moveIntoElement)
      ) {
        return false;
      }

      const moveNextToVersionId = getElementVersionId(moveNextToElement);

      if (moveNextToVersionId) {
        // Get element in rendering order
        const elementVersions = sortedElements.filter(
          (e) => getElementVersionId(e) === moveNextToVersionId,
        );
        const firstId = elementVersions[0]?.id;
        const lastId = elementVersions[elementVersions.length - 1]?.id;
        if (moveType === 'above' && moveToId !== firstId) {
          return false;
        }
        if (moveType === 'below' && moveToId !== lastId) {
          return false;
        }
      }

      const elementsCanBeMoved = elementIds.every((elementId) => {
        const element = getElementById(selectedVersion, elementId);
        const children = getChildElements(moveIntoElement);

        // Can't move element into itself
        if (!element || element.id === moveIntoElement.id) {
          return false;
        }

        // Can't move generated elements
        if (isGeneratedProductElement(element)) {
          return false;
        }

        // Can't move product elements into a building version
        if (
          isProductElement(element) &&
          isBuildingVersionElement(moveIntoElement)
        ) {
          return false;
        }

        // Can't move element below or above itself
        if (moveType !== 'inside' && moveToId === elementId) {
          return false;
        }

        // Can't move element to the parent since it's already there
        if (
          moveType === 'inside' &&
          children.some((child) => child.id === element.id)
        ) {
          return false;
        }

        // Can't move element to a child element
        return !getElementById(element, moveIntoElement.id);
      });
      return elementsCanBeMoved;
    },
    [],
  );

  const moveElementsTo = useCallback(
    async (moveToId: string, moveType?: DropType, ...elementIds: string[]) => {
      if (!isMoveElementsAllowed(moveToId, moveType, ...elementIds)) {
        throw new Error('Move not allowed');
      }

      const project = getProject();

      const projectWithMovedElements = moveElements(
        project,
        moveToId,
        moveType,
        ...elementIds,
      );

      // If all elements are already in the right "folder" we don't need to update the project
      if (projectWithMovedElements !== project) {
        return await updateProject(projectWithMovedElements);
      }

      return projectWithMovedElements;
    },
    [isMoveElementsAllowed, updateProject],
  );

  return { moveElementsTo, isMoveElementsAllowed };
};

/**
 * Get element in which element will be moved to.
 * If moveType is 'inside' it will return the element itself.
 * But if it's 'above' or 'below' it will return the parent.
 * @param moveToId
 * @param moveType
 * @returns
 */
const getMoveIntoElement = <R extends IBuildingVersion | Project>(
  root: R,
  moveToId?: string,
  moveType: DropType = 'inside',
): OneOfParentElements | undefined => {
  if (typeof moveToId !== 'string') {
    throw new Error('selected version not found');
  }

  const moveToElement = getElementById(root, moveToId);

  // After or behind will move the element into the parent
  const moveIntoElement =
    moveType === 'inside'
      ? moveToElement
      : getParentElement(root, moveToElement);

  return isParentElement(moveIntoElement) ? moveIntoElement : undefined;
};

export const moveElements = <R extends IBuildingVersion | Project>(
  root: R,
  moveToId: string,
  moveType: DropType = 'inside',
  ...elementOrIds: ItemOrItemId<OneOfChildElements>[]
): R => {
  if (elementOrIds.length === 0) {
    return root;
  }

  // Get the parent element to move the elements into
  const parent = getMoveIntoElement(root, moveToId, moveType);

  if (!parent || !isParentElement(parent)) {
    throw new Error('Could not find parent element to move to');
  }

  // Get elements to move
  const elements = elementOrIds
    .map((e) => (typeof e === 'string' ? getElementById(root, e) : e))
    .filter(isOneOfChildElements)
    .map(
      (e) =>
        isElementVersionElement(e)
          ? omit(e, 'isActiveVersion', 'versionId')
          : e, // Remove version properties in root elements
    );
  const sibling: OneOfChildElements = getElementById(root, moveToId);
  const placement = moveTypeToSortPlacement(moveType);

  // Remove elements from old positions and keep the updated project & version
  root = removeElements(root, ...elements);

  return addElements(
    root,
    parent,
    { placement, sibling, regenerateIds: false },
    ...elements,
  ).root;
};

const moveTypeToSortPlacement = (dropType: DropType): IInsertSortPlacement => {
  if (dropType === 'inside') return 'last';
  if (dropType === 'above') return 'before';
  if (dropType === 'below') return 'after';
  throw new Error('Invalid drop type');
};
