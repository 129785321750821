import React, {
  MouseEventHandler,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Box, Typography, Button, Tooltip, Theme } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import DeleteIcon from '@mui/icons-material/Delete';
import IosShareIcon from '@mui/icons-material/IosShare';
import { v4 } from 'uuid';
import { useUser } from '../hooks/user.hook';
import { useProjectState } from '../store/project';
import { getSelectedVersionId } from '../store/ui';
import { makeStyles } from 'tss-react/mui';
import { usePromiseSnackbar } from '../hooks/snackbar.hook';
import { useMouseEventCallback } from '../hooks/events.hook';

const ShareProjectButton = () => {
  const {
    project: { sharing_key },
  } = useProjectState('project', 'updateProjectDetails');

  const { classes } = useStyles();
  const version = getSelectedVersionId();
  const promiseSnackbar = usePromiseSnackbar();

  const [url, setUrl] = useState(
    sharing_key ? getUrl(sharing_key, version) : '',
  );

  const copyToClipboard = useCallback(
    (url: string) => {
      if (!url) return;
      setUrl(url);
      promiseSnackbar(() => navigator.clipboard.writeText(url), {
        successMessage: 'Copied to clipboard',
      });
    },
    [promiseSnackbar],
  );

  useEffect(() => {
    if (!sharing_key) {
      setUrl('');
    }
  }, [sharing_key]);

  return (
    <Box className={classes.container}>
      {url ? (
        <SharingLink copyToClipboard={copyToClipboard} url={url} />
      ) : (
        <SharingButton copyToClipboard={copyToClipboard} />
      )}
    </Box>
  );
};

interface SharingLinkProps {
  url: string;
  copyToClipboard: (url: string) => void;
}

const SharingLink = ({ url, copyToClipboard }: SharingLinkProps) => {
  const {
    project: { id },
    updateProjectDetails,
  } = useProjectState('project', 'updateProjectDetails');

  const { classes } = useStyles();

  const handleDeleteSharingClick = useMouseEventCallback(() =>
    updateProjectDetails({ projectId: id, data: { sharing_key: null } }),
  );

  const handleCopyToClipboard = useCallback(() => {
    copyToClipboard(url);
  }, [copyToClipboard, url]);

  return (
    <Box>
      <Typography className={classes.linkHeader}>
        Sharing link&nbsp;
        <DeleteIcon
          className={classes.deleteIcon}
          onClick={handleDeleteSharingClick}
        />
      </Typography>
      <Typography className={classes.linkText} onClick={handleCopyToClipboard}>
        {url}
      </Typography>
    </Box>
  );
};

interface SharingButtonProps {
  copyToClipboard: (url: string) => void;
}

const SharingButton = ({ copyToClipboard }: SharingButtonProps) => {
  const {
    project: { id, owner },
    updateProjectDetails,
  } = useProjectState('project', 'updateProjectDetails');

  const { classes } = useStyles();
  const user = useUser();
  const version = getSelectedVersionId() ?? 'none';
  const sharing_key = v4();

  const isOwner = user?.id === owner;
  const url = getUrl(sharing_key, version);

  const generateSharingLink: MouseEventHandler<HTMLButtonElement> =
    useCallback(async () => {
      await updateProjectDetails({ projectId: id, data: { sharing_key } });
      copyToClipboard(url);
    }, [updateProjectDetails, id, sharing_key, copyToClipboard, url]);

  return (
    <Tooltip
      title={!isOwner ? 'You need to be owner of the project to share it' : ''}
    >
      <div>
        <Button
          variant="outlined"
          size="small"
          color="secondary"
          startIcon={<IosShareIcon />}
          disabled={user?.id !== owner}
          sx={{ padding: '4px 10px' }}
          classes={{ startIcon: classes.buttonIcon }}
          onClick={generateSharingLink}
        >
          <FormattedMessage id="projects_panel.share" defaultMessage="Share" />
        </Button>
      </div>
    </Tooltip>
  );
};

const getUrl = (sharing_key: string, version = 'none') => {
  return `${window.location.origin}/shared/${sharing_key}/v/${version}/elements/mc/none/e/none`;
};

const useStyles = makeStyles()(({ spacing, palette }: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: spacing(150),
  },

  linkHeader: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 700,
    fontSize: '0.75rem',
    '> svg': {
      marginleft: spacing(2),
      fontSize: '0.75rem',
    },
  },

  linkText: {
    display: 'inline-block',
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '0.75rem',
    cursor: 'pointer',
    '&:hover': {
      color: palette.secondary.main,
    },
  },

  deleteIcon: {
    cursor: 'pointer',
    '&:hover': {
      color: palette.secondary.main,
    },
  },

  buttonIcon: {
    marginRight: spacing(1),
    marginTop: -3,
    '& svg': {
      fontSize: '.75rem !important',
    },
  },
}));

export default ShareProjectButton;
