import React, { lazy, Suspense, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelectedOrganization } from '../store/organization';
import { useProject } from '../store/project';
import { useRecipesStore } from '../store/recipe';
import { useUIState } from '../store/ui';
import amplitudeLog from '../amplitude';
import { capitalize } from 'lodash';
import { usePrevious } from '../hooks/hooks';
import EditProductDialog from '../projects/EditProject/EditProductDialog';
import { useInitElementsSortFilter } from '../hooks/filter-elements.hook';
import { Box } from '@mui/material';
import PageNavigation from '../components/PageNavigation';
import MainCategoriesOverview from '../components/ElementList/MainCategories/MainCategoriesOverview';
import ProjectViewHeader from './ProjectViewHeader';
import Proposals from '../components/Proposals';
import SortElementsSelect from '../components/sorting/SortElementsSelect';
import SearchElements from '../components/filtering/SearchElements';

const ElementsView = lazy(() => import('./ElementsView'));
const ProductsView = lazy(() => import('./ProductsView'));
const Calculations = lazy(() => import('../calculations/Calculations'));

const ProjectView: React.FC = () => {
  const { selectedPage } = useUIState('selectedPage');
  const project = useProject();
  const selectedOrganization = useSelectedOrganization();
  const prevSelectedPage = usePrevious(selectedPage);
  const navigate = useNavigate();

  useInitElementsSortFilter();

  const [organizations, setOrganizations] = useState(
    project?.organizations ?? [],
  );

  const fetchRecipes = useRecipesStore(
    useCallback(({ fetchRecipes }) => fetchRecipes, []),
  );

  useEffect(() => {
    const projOrgs = project?.organizations;
    const id = project?.id;
    if (projOrgs && id) {
      const hasNewOrganization = organizations?.some(
        (organization) => !projOrgs.includes(organization),
      );
      if (hasNewOrganization) {
        setOrganizations(projOrgs);
        fetchRecipes();
      }
    }
  }, [
    fetchRecipes,
    project?.id,
    project?.organizations,
    navigate,
    organizations,
    selectedOrganization,
  ]);

  useEffect(() => {
    if (prevSelectedPage !== selectedPage) {
      amplitudeLog('Navigation To ' + capitalize(selectedPage));
    }
  }, [prevSelectedPage, selectedPage]);

  return (
    <>
      <ProjectViewHeader />
      <Proposals />
      <MainCategoriesOverview />

      <Box display="flex" justifyContent="space-between" pr={2} pl={2}>
        <PageNavigation />

        <Box display="flex" alignItems="center" gap={2}>
          <SortElementsSelect />
          <SearchElements variant="elements" />
        </Box>
      </Box>

      {selectedPage === 'elements' && (
        <Suspense fallback={null}>
          <ElementsView />
        </Suspense>
      )}

      {selectedPage === 'products' && (
        <Suspense fallback={null}>
          <ProductsView />
        </Suspense>
      )}
      {selectedPage === 'calculations' && (
        <Suspense fallback={null}>
          <Calculations />
        </Suspense>
      )}

      <EditProductDialog />
    </>
  );
};

export default ProjectView;
