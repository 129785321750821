import {
  TEMPLATE_ORGANIZATION,
  GFA_QUOTA_UNLIMITED,
} from '../../shared/constants';
import { Recipe } from '../../shared/models/recipe.interface';
import { Product } from '../models/product.interface';
import { UserOrganization } from '../models/user.interface';

export const getSelectedOrganizationQuota = (
  orgs: UserOrganization[] = [],
  selectedOrg = '',
): number | undefined => {
  const match = orgs.find((org) => org.name === selectedOrg);
  if (!match || match.metadata?.gfa_quota === GFA_QUOTA_UNLIMITED) {
    return;
  }

  // disable the error logging for now
  // const msg = `Organization "${match.display_name}" has no square meter quota set`;

  if (!match.metadata || isNaN(Number(match.metadata.gfa_quota))) {
    // console.error(msg);

    return;
  }

  return Number(match.metadata.gfa_quota);
};

export const itemBelongsToTemplateOrganization = (
  item?: Recipe | Product,
): boolean => {
  return !!item?.organizations?.includes(TEMPLATE_ORGANIZATION);
};

export const itemIsReadonly = (
  selectedOrganization: string | undefined,
  item?: Recipe | Product,
): boolean => {
  return selectedOrganization === TEMPLATE_ORGANIZATION
    ? false
    : itemBelongsToTemplateOrganization(item);
};
