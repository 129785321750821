import React, {
  ChangeEventHandler,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { Box, BoxProps, SelectProps, SxProps, TextField } from '@mui/material';
import { ElementCategoryID } from '../../../../../../shared/models/element_categories.interface';
import ElementProperties from '../Property/ElementProperties';
import { isMainCategory } from '../../../../../../shared/templates/categories';
import { useIsReadonly } from '../../../../hooks/user.hook';
import { isElementCategoryID } from '../../../../../../shared/helpers/element_category_helpers';
import { useElementPropertiesUtils } from '../../../../hooks/element-properties.hook';
import { IElement } from '../../../../../../shared/models/project.interface';
import {
  setLastSelectedElementCategoryId,
  useSelectElementCategory,
} from '../../../../hooks/element-category.hook';
import { useElementCategoryItems } from './ElementCategoryItems';
import { useUIState } from '../../../../store/ui';
import { useBooleanState } from '../../../../hooks/hooks';

interface ElementCategoryMenuProps extends BoxProps {
  element: IElement;
  filterCategories?: Readonly<ElementCategoryID[]>;
  filterProperties?: string[];
}

const ElementCategoryMenu: React.FC<ElementCategoryMenuProps> = ({
  element,
  filterCategories,
  filterProperties,
  ...boxProps
}) => {
  const { addedElementId, addedElementOriginalId, setAddedElementId } =
    useUIState('addedElementId', 'addedElementOriginalId', 'setAddedElementId');

  const selectElementCategory = useSelectElementCategory(element);
  const { categoryProperties } = useElementPropertiesUtils(element);

  const { category_id, versionId, id } = element;
  const isMainEC = isMainCategory(category_id);
  const readonly = useIsReadonly();

  const items = useElementCategoryItems(element, filterCategories);

  const [open, showMenu, hideMenu] = useBooleanState(false);

  const selectProps = useMemo<Partial<SelectProps>>(
    () => ({
      inputProps: { sx: { display: 'flex' } },
      open,
      onClose: hideMenu,
      onOpen: showMenu,
    }),
    [hideMenu, open, showMenu],
  );

  const handleChange = useCallback<
    ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
  >(
    ({ target: { value } }) => {
      const selectedCategory = value as ElementCategoryID;
      selectElementCategory(selectedCategory);

      if (selectedCategory !== ElementCategoryID.Maintenance) {
        setLastSelectedElementCategoryId(selectedCategory);
      }
    },
    [selectElementCategory],
  );

  useEffect(() => {
    if (id === addedElementId && !versionId && !addedElementOriginalId) {
      showMenu();
      setAddedElementId(undefined);
    }
  }, [
    addedElementId,
    addedElementOriginalId,
    id,
    setAddedElementId,
    showMenu,
    versionId,
  ]);

  return (
    <Box {...boxProps}>
      {!isMainEC && (
        <TextField
          select
          fullWidth
          size="small"
          disabled={readonly}
          SelectProps={selectProps}
          sx={boxStyles}
          value={isElementCategoryID(category_id) ? category_id : ''}
          onChange={handleChange}
        >
          {...items}
        </TextField>
      )}
      {category_id && (
        <ElementProperties
          element={element}
          properties={categoryProperties}
          filterProperties={filterProperties}
        />
      )}
    </Box>
  );
};

const boxStyles: SxProps = { paddingBottom: 1 } as const;

export default ElementCategoryMenu;
