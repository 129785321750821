import React, {
  CSSProperties,
  useCallback,
  MouseEvent,
  ReactNode,
  useMemo,
} from 'react';
import {
  Button,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import {
  ArrowDropDown,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from '@mui/icons-material';
import { labelFromValue } from '../../../shared/helpers/element_property_factory_helpers';
import { isObject } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { OptionalReadonly } from '../../../shared/models/type_helpers.interface';
import { elementListItemSelectIconStyles } from '../style/constants';

export const NODON_SELECT_ID = 'nodon-select';
export const NODON_SELECT_OPEN_ID = 'nodon-select-open';

type NodonSelectValue = string | number | boolean | undefined;

interface INodonSelectOption<T extends NodonSelectValue> {
  label?: string | ReactNode;
  icon?: ReactNode;
  value: T;
}

interface NodonSelectProps<T extends NodonSelectValue = string> {
  buttonLabel?: string;
  tooltipTitle?: string;
  options: OptionalReadonly<(T | INodonSelectOption<T>)[]>;
  formatOptionLabels?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  parentContainerIsButton?: boolean;
  className?: string;
  buttonStyles?: CSSProperties;
  onChange: (value: T) => void;
}

const NodonSelect = <T extends NodonSelectValue>({
  className,
  buttonLabel,
  tooltipTitle,
  options,
  onChange,
  fullWidth,
  disabled = false,
  buttonStyles,
  parentContainerIsButton,
  formatOptionLabels = true,
}: NodonSelectProps<T>) => {
  const formattedOptions = useMemo(
    () => createSelectOptions(options, formatOptionLabels),
    [options, formatOptionLabels],
  );
  const showIcon = formattedOptions.some((option) => !!option.icon);
  const { classes, cx } = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleButtonClick: React.MouseEventHandler = useCallback(
    (e: MouseEvent<HTMLInputElement>) => setAnchorEl(e.currentTarget),
    [],
  );

  const handleMenuClose = useCallback(() => setAnchorEl(null), []);

  return (
    <>
      {!buttonLabel && (
        <Tooltip title={tooltipTitle}>
          <IconButton
            size="small"
            color="inherit"
            disabled={disabled}
            edge="end"
            style={buttonStyles}
            sx={elementListItemSelectIconStyles}
            onClick={handleButtonClick}
          >
            <ArrowDropDown />
          </IconButton>
        </Tooltip>
      )}
      {!!buttonLabel && (
        <Button
          color="secondary"
          disabled={disabled}
          fullWidth={fullWidth}
          component={parentContainerIsButton ? 'span' : 'button'}
          style={buttonStyles}
          className={cx(className, classes.buttonRoot, NODON_SELECT_ID)}
          endIcon={anchorEl ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          onClick={handleButtonClick}
        >
          {buttonLabel}
        </Button>
      )}
      <Menu
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleMenuClose}
        PopoverClasses={{
          paper: cx(NODON_SELECT_ID, NODON_SELECT_OPEN_ID),
        }}
      >
        {formattedOptions.map(({ value, icon, label }) => (
          <MenuItem
            key={String(value)}
            // value={option.value}
            // component="" // Add the component prop with the value "div"
            onClick={(e) => {
              onChange(value);
              setAnchorEl(null);
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            {showIcon && <ListItemIcon sx={ICON_PADDING}>{icon}</ListItemIcon>}
            <ListItemText>
              {typeof label === 'string' ? (
                <FormattedMessage
                  id={`select-menu-${label}`}
                  defaultMessage={label}
                />
              ) : (
                label
              )}
            </ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

const ICON_PADDING = {
  padding: 1,
};

const useStyles = makeStyles()(() => ({
  buttonRoot: {
    textTransform: 'none',
    minWidth: 48,
  },
}));

/**
 * Create a list off select options with better labels based on values
 * @param options
 * @returns
 */
export const createSelectOptions = <T extends NodonSelectValue>(
  options: OptionalReadonly<(T | INodonSelectOption<T>)[]>,
  formatLabel = true,
): INodonSelectOption<T>[] =>
  options.map((option) => {
    const isObj = isObject(option);
    const value = isObj ? option.value : option;
    const label = (isObj && option.label) || labelFromValue(value, formatLabel);
    const icon = isObj ? option.icon : undefined;
    return { label, value, icon };
  });

export default NodonSelect;
