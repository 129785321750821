import React from 'react';
import { Typography, Tooltip } from '@mui/material';
import HorizontalBarCharts from '../charts/HorizontalBarCharts';
import { IProductElementArticle } from '../../../../shared/models/product.interface';
import { useBooleanState } from '../../hooks/hooks';
import { Row } from '../ElementList/Row';
import {
  CONTENT_CELL_WIDTH_RESPONSIVE,
  LIST_ITEM_HEIGHT,
  LIST_SPACING,
  ROOT_CELL_WIDTH,
  ROOT_CELL_WIDTH_RESPONSIVE,
} from '../ElementList/list.constants';
import { RowCell } from '../ElementList/RowCell';
import ExpressionTypography from '../ExpressionTypography';

const PATH_MAX_LENGTH = 75;

interface ProductsListChildItemProps {
  article: IProductElementArticle;
}

const ProductsListChildItem: React.FC<ProductsListChildItemProps> = ({
  article,
}) => {
  const formattedPath = getTruncatedPath(article.path.join(' / '));

  const [hover, hoverStart, hoverEnd] = useBooleanState();

  return (
    <Row
      height={LIST_ITEM_HEIGHT}
      spacing={LIST_SPACING}
      padding={true}
      hover={hover}
      onMouseOver={hoverStart}
      onMouseLeave={hoverEnd}
    >
      {/* Align names with parent */}
      <RowCell width={ROOT_CELL_WIDTH.ICON} />
      <RowCell
        width={ROOT_CELL_WIDTH.CONTENT}
        responsiveWidth={ROOT_CELL_WIDTH_RESPONSIVE.CONTENT}
      >
        <Row height={LIST_ITEM_HEIGHT} spacing={LIST_SPACING}>
          {/* Name */}
          <RowCell indentation={1}>
            <Typography variant="body2" noWrap>
              <Tooltip
                title={formattedPath}
                disableHoverListener={formattedPath.length <= PATH_MAX_LENGTH}
                disableInteractive
              >
                <span>{formattedPath}</span>
              </Tooltip>
            </Typography>
          </RowCell>
          {/* Quantity */}
          <RowCell
            align="right"
            width={CONTENT_CELL_WIDTH_RESPONSIVE.EXPRESSION}
            responsiveWidth={CONTENT_CELL_WIDTH_RESPONSIVE.EXPRESSION}
          >
            <ExpressionTypography
              value={article.quantity}
              unit={article.unit}
            />
          </RowCell>
        </Row>
      </RowCell>
      {/* Bar chart */}
      <RowCell
        width={ROOT_CELL_WIDTH.BAR}
        responsiveWidth={ROOT_CELL_WIDTH_RESPONSIVE.BAR}
      >
        <HorizontalBarCharts
          co2e={article.co2e}
          cost={article.cost}
          hover={hover}
          isForProducts
        />
      </RowCell>
    </Row>
  );
};

const getTruncatedPath = (path: string = ''): string => {
  const parts = path.split(' / ');

  if (path.length > PATH_MAX_LENGTH && parts.length > 2) {
    const first = parts[0];
    const last = parts[parts.length - 1];
    const middle = ' / ... / ';

    return first + middle + last;
  }
  return path;
};

export default ProductsListChildItem;
