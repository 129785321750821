import React, { useCallback, useMemo } from 'react';
import {
  Add,
  DeleteForever,
  ContentCopy,
  LibraryAddCheck,
} from '@mui/icons-material';
import { IElement } from '../../../../../shared/models/project.interface';
import { isElement } from '../../../../../shared/helpers/recursive_element_helpers';
import amplitudeLog from '../../../amplitude';
import {
  useAddStandardElement,
  useDuplicateElement,
  useRemoveElements,
} from '../../../store/project';
import { useSortedElementVersions } from '../../../hooks/element-version.hook';
import {
  useSelectElementByProposals,
  useAvailableProposals,
  useGetProposalColor,
  useProposals,
  useProposalsUsingElement,
  useRemoveElementFromProposal,
} from '../../../hooks/proposals.hook';
import { IProposal } from '../../../../../shared/models/proposals.interface';
import { isElementWithGeneratedChildren } from '../../../../../shared/helpers/element_helpers';
import { useIsReadonly } from '../../../hooks/user.hook';
import {
  KebabButtonMenu,
  IMenuItemData,
  IKebabMenuInput,
} from '../../SelectMenu/KebabButtonMenu';
import { KebabButtonProps } from '../../SelectMenu/KebabButton';

interface IElementKebabMenuInput extends IKebabMenuInput {
  element: IElement;
}

const ElementKebabMenu: React.FC<IElementKebabMenuInput> = ({
  element,
  anchor,
  onOpen,
  onClose,
}) => {
  const addElement = useAddStandardElement();
  const duplicateElement = useDuplicateElement();
  const removeElements = useRemoveElements({ showConfirm: true });
  const isGenerated = isElementWithGeneratedChildren(element);

  const { id } = element;

  const allProposals = useProposals();
  const availableProposals = useAvailableProposals(element);
  const elementVersions = useSortedElementVersions(element);
  const proposalsUsingElement = useProposalsUsingElement(element);
  const selectElementByProposals = useSelectElementByProposals();
  const removeElementFromProposal = useRemoveElementFromProposal();
  const getProposalColor = useGetProposalColor();

  if (!id) {
    throw new Error('Element ID is required');
  }

  const handleRemoveElement = useCallback(
    () => removeElements(id),
    [id, removeElements],
  );

  const toggleProposal = useCallback(
    (proposal: IProposal, checked: boolean) => {
      if (!isElement(element)) {
        return;
      }
      if (checked) {
        selectElementByProposals(element, proposal);
      } else {
        removeElementFromProposal(element, proposal);
      }
    },
    [selectElementByProposals, element, removeElementFromProposal],
  );

  const propsalItems: IMenuItemData[] = useMemo(() => {
    return availableProposals.map((proposal) => {
      const checked = proposalsUsingElement.includes(proposal);

      return {
        onClick: () => {
          toggleProposal(proposal, !checked);
          onClose?.();
        },
        id: `proposal.select[${element.id}][${proposal.id}`,
        label: `Use by proposal ${proposal.name}`,
        checked,
        checkboxColor: getProposalColor(proposal),
      };
    });
  }, [
    availableProposals,
    element.id,
    getProposalColor,
    onClose,
    proposalsUsingElement,
    toggleProposal,
  ]);

  const items = useMemo(() => {
    // Can't add element to generated elements
    const nonGenerated: IMenuItemData[] = !isGenerated
      ? [
          {
            onClick: async () => {
              await addElement(element);
              amplitudeLog('Element New', { Place: 'Nested' });
              onClose?.();
            },
            icon: <Add color="secondary" />,
            id: 'elementVersion.addElement',
            label: 'Add element',
          },
        ]
      : [];

    const useByAllProposals: IMenuItemData[] =
      availableProposals.length &&
      availableProposals.length !== proposalsUsingElement.length
        ? [
            {
              onClick: () => {
                selectElementByProposals(element, availableProposals);
                onClose?.();
              },
              id: 'proposal.selectAll',
              icon: <LibraryAddCheck color="secondary" />,
              label: 'Use by all proposals',
            },
          ]
        : [];

    return [
      ...nonGenerated,
      // {
      //   onClick: () => {
      //     amplitudeLog('Element Version Duplicate', {
      //       ElementID: id,
      //     });
      //     onClose?.();
      //     addElementVersion(element);
      //   },
      //   icon: <ContentCopy color="secondary" />,
      //   id: 'elementVersion.duplicate',
      //   label: 'Duplicate version',
      //   disabled: !allProposals.length,
      // },
      {
        onClick: () => {
          amplitudeLog('Element Duplicate', {
            ElementID: id,
          });
          duplicateElement(element);
          onClose?.();
        },
        icon: <ContentCopy color="secondary" />,
        id: 'elementVersion.duplicate',
        label: 'Duplicate element',
        disabled: !allProposals.length,
      },
      {
        onClick: handleRemoveElement,
        icon: <DeleteForever color="secondary" />,
        id: 'elementVersion.delete',
        label: 'Delete ' + (elementVersions.length > 1 ? 'version' : 'element'),
      },
      ...useByAllProposals,
      ...propsalItems,
    ];
  }, [
    isGenerated,
    availableProposals,
    proposalsUsingElement.length,
    allProposals.length,
    handleRemoveElement,
    elementVersions.length,
    propsalItems,
    addElement,
    element,
    onClose,
    selectElementByProposals,
    id,
    duplicateElement,
  ]);

  const readonly = useIsReadonly();

  return (
    !readonly && (
      <KebabButtonMenu
        id={id}
        items={items}
        menuWidth={250}
        kebabButtonProps={kebabButtonProps}
        anchor={anchor}
        onOpen={onOpen}
        onClose={onClose}
      />
    )
  );
};

const kebabButtonProps: Partial<KebabButtonProps> = {
  tooltipTitle: 'Element options',
} as const;

export default ElementKebabMenu;
