import React, { FC, useMemo } from 'react';
import { Box, List, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { getResultsById } from '../../../../shared/helpers/results.helpers';
import { isBuildingVersionElement } from '../../../../shared/helpers/recursive_element_helpers';
import { ResultsRecord } from '../../../../shared/models/unit.interface';
import {
  ProductID,
  ProductRecord,
  IProductElementArticle,
  IProductElementParentItem,
} from '../../../../shared/models/product.interface';
import {
  IProductElement,
  IBuildingVersion,
} from '../../../../shared/models/project.interface';
import ProductsListParentItem from './ProductsListParentItem';
import { useVersionResultRecord } from '../../hooks/results.hook';
import { useGroupedProductElements } from '../../hooks/useProducts';
import { sortProductsByCO2 } from '../../../../shared/helpers/sort_helpers';
import { useProductsLookup } from '../../store/product';
import { useSelectedVersion } from '../../store/ui';
import { getProductById } from '../../../../shared/helpers/product_helpers';
import { getElementName } from '../../../../shared/helpers/element_helpers';
import { getPathFromFlattenedElements } from '../../hooks/filter-elements.hook';
import { sumConversionFactors } from '../../../../shared/helpers/conversion-factors.helpers';

interface ProductsListProps {
  selectedProductId: ProductID | undefined;
  setSelectedProductId: (id: ProductID) => void;
}

const ProductsList: FC<ProductsListProps> = ({
  selectedProductId,
  setSelectedProductId,
}) => {
  const { classes } = useStyles();
  const selectedVersion = useSelectedVersion();
  const productsLookup = useProductsLookup();
  const quantitiesRecord = useVersionResultRecord();
  const groupedProductElements = useGroupedProductElements();
  const productElementItems = useMemo(
    () =>
      Object.entries(groupedProductElements)
        .map(([productId, productElements]) =>
          getProductElementData(
            productId,
            productElements,
            selectedVersion,
            productsLookup,
            quantitiesRecord,
          ),
        )
        .filter((parentItem) => parentItem.quantitiesSum),
    [productsLookup, groupedProductElements, quantitiesRecord, selectedVersion],
  );

  const sortedProductElementItems = useMemo(
    () => [...productElementItems].sort(sortProductsByCO2),
    [productElementItems],
  );

  return (
    <Box width="100%">
      {sortedProductElementItems.length ? (
        <List className={classes.list}>
          {sortedProductElementItems.map((productElementData) => {
            return (
              <ProductsListParentItem
                key={productElementData.productId}
                {...productElementData}
                selectedProductId={selectedProductId}
                setSelectedProductId={setSelectedProductId}
              />
            );
          })}
        </List>
      ) : (
        <Box
          height="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Typography variant="h6" color="GrayText">
            No products to show
          </Typography>
        </Box>
      )}
    </Box>
  );
};

const useStyles = makeStyles()(() => ({
  list: {
    width: '100%',
    padding: '8px 16px',
  },
}));

export const getProductElementData = (
  productId: string,
  productElements: IProductElement[],
  selectedVersion: IBuildingVersion | undefined,
  products: ProductRecord,
  quantitiesRecord: ResultsRecord,
): IProductElementParentItem => {
  if (!selectedVersion) {
    throw new Error('could not find selected version');
  }

  // Version should be enough here I think, else a bug must have happened
  const product = getProductById(products, selectedVersion, productId, true);

  const { name, unit } = product;
  let [quantitiesSum, co2eSum, costSum] = [0, 0, 0];

  const disableProductSwitch = productElements.every(
    (element) => element.generated,
  );
  const sums = sumConversionFactors(
    ...productElements.map((element) =>
      getResultsById(quantitiesRecord, element.id),
    ),
  );

  const articles: IProductElementArticle[] = productElements.map((element) => {
    const quantities = getResultsById(quantitiesRecord, element.id);
    const co2e = quantities['co2e_total'] ?? 0;
    const cost = quantities['sek_A1-A3'] ?? 0;

    // Use product unit for sum to make sure all quantities are in the same unit
    quantitiesSum += quantities[unit] ?? 0;
    co2eSum += co2e;
    costSum += cost;

    return {
      path: getElementProductPath(selectedVersion, element),
      quantity: quantities[element.unit] ?? 0, // Qauntity in the unit of the element
      co2e,
      cost,
      ...element,
    };
  });

  return {
    productId,
    name,
    unit,
    quantitiesSum,
    co2eSum,
    costSum,
    sums,
    articles,
    disableProductSwitch,
  };
};

const getElementProductPath = (
  version: IBuildingVersion,
  element: IProductElement,
): string[] => {
  // Support deeper nesting of elements
  const path = getPathFromFlattenedElements(element) // Fastest way to get the path
    .filter((element) => !isBuildingVersionElement(element))
    .map((element) => getElementName(element));

  if (!path.length) {
    throw new Error('could not find group element');
  }
  return path;
};

export default ProductsList;
