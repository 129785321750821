import { v4 } from 'uuid';
import {
  DEFAULT_PROPOSAL_NAME,
  IProposal,
} from '../models/proposals.interface';
import {
  ArrayOrSingle,
  ItemOrItemId,
  SemiPartial,
} from '../models/type_helpers.interface';
import {
  getId,
  omit,
  omitUndefined,
  replaceProperties,
} from './object_helpers';
import {
  ElementKind,
  IBuildingVersion,
  IElement,
  OneOfElementListElements,
  OneOfElements,
  OneOfParentElements,
} from '../models/project.interface';
import { required } from './function_helpers';
import { findFreeName } from './string_helpers';
import { EMPTY_ARRAY, asArray, isDefined } from './array_helpers';
import {
  addElementVersion,
  addElementVersionId,
  cleanupElementVersions,
  deativateElementVersion,
  ElementOrVersionId,
  getElementVersionId,
  getElementVersionIds,
  getElementVersions,
  getElementVersionsById,
  isElementVersionElement,
  setActiveElementVersion,
} from './element-version.helpers';
import {
  flattenElements,
  forEachElement,
  getElementById,
  getPathAndElementById,
  getPathToElement,
  isBuildingVersionElement,
  isElement,
  isOneOfParentElements,
} from './recursive_element_helpers';
import { isEqual, isObject, last } from 'lodash';
import { IElementVersionMap } from './element_factory_helpers';
import { updateElements } from './project_helpers';
import { requiredKind } from './element_helpers';

type VersionOrProposals = IBuildingVersion | IProposal[];

const PROPOSAL_COLORS = [
  '#E99E94',
  '#DFE184',
  '#A3D3DB',

  // Extra colors
  '#BC9BE3',
  '#6D9E6D',
];

/**
 * Create a new proposal. Pass existing proposal to duplicate
 * @param defaults
 * @param versionMap When duplicating versions we need to remap selections to new ids
 * @returns
 */
export const createProposal = (
  defaults: Partial<IProposal>,
  maps?: IElementVersionMap,
): IProposal => {
  return {
    name: DEFAULT_PROPOSAL_NAME,
    active: false,
    resultsRecord: {},
    ...omitUndefined(defaults),
    selections: remapProposalSelections(defaults.selections, maps),
    id: v4(), // Always make new id
  };
};

/**
 * Options for remapping proposal selections
 */
interface IRemapProposalSelectionsOptions {
  /**
   * If true, it will keep the original/previous selections
   */
  keepOriginalSelections?: boolean;
  /**
   * If true, it will add all active elements to the proposal
   */
  selectActiveElements?: boolean;
}

/**
 * Remap proposal selections to new ids
 * @param selections
 * @param maps previousId => newId maps.
 * @param options
 * @returns
 */
export const remapProposalSelections = (
  selections: IProposal['selections'] = {},
  {
    versionMap = new Map(),
    elementMap = new Map(),
    activeElementVersionRecord = {},
  }: Partial<IElementVersionMap> = {},
  {
    keepOriginalSelections = false,
    selectActiveElements = false,
  }: IRemapProposalSelectionsOptions = {},
): IProposal['selections'] => {
  if (
    !versionMap.size &&
    !elementMap.size &&
    !Object.keys(activeElementVersionRecord).length
  ) {
    return selections;
  }

  let remapped = Object.entries(selections).reduce(
    (arr, [versionId, elementId]) => {
      // Skip adding undefined selections
      if (!elementId) {
        return arr;
      }

      const newVersionId = versionMap.get(versionId) ?? versionId;
      const newElementId = elementMap.get(elementId) ?? elementId;

      // If we want to keep original selections and the selection already exist, skip it
      if (keepOriginalSelections && arr[newVersionId]) {
        return arr;
      }

      return { ...arr, [newVersionId]: newElementId };
    },
    // If we want to keep original selections and the selection already exist, skip it
    keepOriginalSelections ? selections : {},
  );

  if (selectActiveElements) {
    remapped = { ...activeElementVersionRecord, ...remapped };
  }
  return isEqual(remapped, selections) ? selections : remapped;
};

export const addProposal = (
  version: IBuildingVersion,
  defaults: Partial<IProposal> = {},
): IBuildingVersion => {
  const proposals = getProposalsInVersion(version);
  const name = findFreeName(proposals, defaults.name ?? 'Proposal 1');

  const updatedVersion = replaceProperties(version, {
    proposals: [...proposals, createProposal({ ...defaults, name })],
  });
  return selectProposal(
    updatedVersion,
    required(last(getProposalsInVersion(updatedVersion))),
  );
};

export const updateElementActiveVersionStates = (version: IBuildingVersion) => {
  const proposals = getProposalsInVersion(version);
  const activeProposal = getActiveProposal(version);

  if (!activeProposal) {
    return version;
  }

  const updates: SemiPartial<OneOfParentElements, 'id'>[] = [];

  forEachElement(version, (element, path) => {
    const parent = last(path);

    // Only update element versions
    if (isOneOfParentElements(parent) && isElementVersionElement(element)) {
      const referringProposals = getProposalsUsingElement(
        proposals,
        path,
        element,
      );

      const isActiveVersion = referringProposals.includes(activeProposal);

      // Note undefined and false are considered the same thing that's why we use "!!"
      if (!!element.isActiveVersion !== isActiveVersion) {
        updates.push({
          id: element.id,
          isActiveVersion,
        } as SemiPartial<IElement, 'id'>);
      }
    }
  });

  return updateElements(version, ...updates);
};

export const getProposalsInVersion = (
  versionOrProposals: IProposal[] | IBuildingVersion | undefined,
): IProposal[] => {
  const proposals = Array.isArray(versionOrProposals)
    ? versionOrProposals
    : (versionOrProposals?.proposals ?? (EMPTY_ARRAY as IProposal[]));
  // Temp migration, delete after merge to dev (only affect me and fredrik)
  for (const proposal of proposals) {
    if (
      'selectedVersions' in proposal &&
      isObject(proposal.selectedVersions) &&
      !proposal.selections
    ) {
      proposal.selections =
        proposal.selectedVersions as IProposal['selections'];
      delete proposal.selectedVersions;
    }
  }
  return proposals;
};

/**
 * Get proposal by id, crash if not found
 * @param version
 * @param proposalOrId
 * @returns
 */
export const getProposalById = (
  version: IBuildingVersion,
  proposalOrId: ItemOrItemId<IProposal>,
): IProposal => {
  const id = getId(proposalOrId);
  return required(getProposalsInVersion(version).find((p) => p.id === id));
};

/**
 * Set proposal as active
 * @param version
 * @param proposalOrId
 * @returns
 */
export const selectProposal = (
  version: IBuildingVersion,
  proposalOrId: ItemOrItemId<IProposal>,
): IBuildingVersion => {
  const id = getId(proposalOrId);
  const proposals = version.proposals || [];
  const updatedProposals = proposals.map((p) => ({
    ...p,
    active: p.id === id,
  }));

  return replaceProperties(version, { proposals: updatedProposals });
};

/**
 * Update proposal with new values (partial)
 * @param version
 * @param proposal
 * @returns
 */
export const updateProposals = (
  version: IBuildingVersion,
  ...updates: SemiPartial<IProposal, 'id'>[]
): IBuildingVersion => {
  const currentProposals = getProposalsInVersion(version);
  if (updates.some(({ id }) => !currentProposals.some((p) => p.id === id))) {
    throw new Error('Proposal not found');
  }
  const proposals = currentProposals.map((p) => {
    const update = updates.find((u) => u.id === p.id);
    return update ? replaceProperties(p, update) : p;
  });

  return replaceProperties(version, { proposals }, true);
};

export const deleteProposal = (
  version: IBuildingVersion,
  proposalOrId: ItemOrItemId<IProposal>,
): IBuildingVersion => {
  const id = getId(proposalOrId);

  const updatedVersion = cleanupElementVersions({
    ...version,
    proposals: getProposalsInVersion(version).filter((p) => p.id !== id),
  });
  const updatedProposals = getProposalsInVersion(updatedVersion);

  // If no proposal is active, select the last one
  if (!updatedProposals.some((p) => p.active)) {
    return selectProposal(updatedVersion, required(last(updatedProposals)));
  }
  return updatedVersion;
};

/**
 * Add element to proposal
 * @param version
 * @param proposalsOrId
 * @param element
 * @param skipCleanup If true, the cleanup of obsolete element versions will be skipped
 * @returns
 */
export const selectElementByProposals = (
  version: IBuildingVersion,
  proposalsOrId: ArrayOrSingle<ItemOrItemId<IProposal>>,
  elementOrId: ItemOrItemId<IElement>,
  skipCleanup = false,
): IBuildingVersion => {
  const element = isElement(elementOrId)
    ? elementOrId
    : requiredKind(getElementById(version, elementOrId), ElementKind.Element);

  const { id, versionId } = element;

  if (!versionId) {
    throw new Error('Element need a version id');
  }

  const path = getPathToElement(version, element);
  const isSingleVersion =
    getElementVersionsById(required(last(path)), element).length === 1;
  const availableProposals = getAvailableProposals(
    version.proposals,
    getPathToElement(version, element),
  );
  const proposals = asArray(proposalsOrId)
    .map((proposalOrId) => getProposalById(version, proposalOrId))
    .filter((p) => availableProposals.includes(p));

  const updates = proposals.map((proposal) => ({
    id: proposal.id,
    selections: { ...proposal.selections, [versionId]: id },
  }));

  // Update version with new selections
  version = updateProposals(version, ...updates);

  // Remove from all proposals if a single element version is added to all proposals
  if (
    !skipCleanup &&
    isSingleVersion &&
    getProposalsWithElementSelected(version.proposals ?? [], element).length >=
      availableProposals.length
  ) {
    version = removeElementFromAllProposals(version, element);
    version = updateElements<IElement, IBuildingVersion>(version, {
      id: element.id,
      versionId: undefined,
      isActiveVersion: undefined,
    });
  }
  // If element is added to active proposal, activate element (and deactivate others)
  return updateElementActiveStateByActiveProposal(version, element);
};

export const isSelectedByProposal = (
  proposal: IProposal | undefined,
  elementOrId: ItemOrItemId<OneOfElementListElements>,
): boolean =>
  Object.values(proposal?.selections ?? {}).includes(getId(elementOrId));

/**
 * Add element version to to proposals without selections
 * @param version
 * @param element
 * @returns An updated version with the new proposal selections
 */
export const addElementVersionToUnselectedProposals = (
  version: IBuildingVersion,
  element: IElement,
): IBuildingVersion => {
  const proposals = getProposalsInVersion(version);
  const versionId = getElementVersionId(element);

  // Can't add element to proposal if no version id or no proposals
  if (proposals.length === 0 || !versionId) {
    return version;
  }

  const proposalsWithoutSelection = proposals.filter(
    (p) => !getSelectedElementId(p, versionId),
  );

  return selectElementByProposals(version, proposalsWithoutSelection, element);
};

export const removeElementFromProposal = (
  version: IBuildingVersion,
  proposalOrId: ItemOrItemId<IProposal>,
  element: IElement,
): IBuildingVersion => {
  const getElement = (): IElement =>
    getElementById(version, getId(element), true);

  if (!isElementVersionElement(element)) {
    version = addElementVersionId(version, element);
    version = selectElementByProposals(
      version,
      version.proposals ?? [],
      getElement(),
      true,
    );
  }

  // All ids that needs to be removed
  const ids = flattenElements(getElement())
    .filter(isElementVersionElement)
    .map(getId);

  // All children need to be removed as well
  ids.forEach((id) => {
    // Elements are updated each iteration
    const el = getElementById(version, id);
    const proposal = getProposalById(version, proposalOrId);
    const versionId = getElementVersionId(el);

    // Not selected in proposal
    if (!isSelectedByProposal(proposal, id)) {
      return version;
    }

    // Replace version for each update
    version = updateProposals(version, {
      id: proposal.id,
      selections: omit(proposal.selections, versionId),
    });

    // Update active state
    version = updateElementActiveStateByActiveProposal(
      version,
      getElementById(version, id, true), // Find element again since it's updated
    );
  });

  return version;
};

export const removeElementFromAllProposals = (
  version: IBuildingVersion,
  element: IElement,
): IBuildingVersion => {
  const proposals = getProposalsInVersion(version);
  const versionId = getElementVersionId(element);

  if (!versionId) {
    return version;
  }

  const updates = proposals.map((p) => ({
    id: p.id,
    selections: omit(p.selections, versionId),
  }));

  return updateProposals(version, ...updates);
};

/**
 * Enable or disable element depedning if it's selected in active proposal
 * @param version
 * @param element
 * @returns
 */
const updateElementActiveStateByActiveProposal = (
  version: IBuildingVersion,
  element: IElement,
): IBuildingVersion => {
  const activeProposal = getActiveProposal(version);

  if (!activeProposal || !isElementVersionElement(element)) {
    return version;
  }
  const selectedElementId = getSelectedElementId(activeProposal, element);

  // If version have a selected element, activate that version (not just this element)
  if (selectedElementId) {
    return setActiveElementVersion(version, selectedElementId);
  } else {
    return deativateElementVersion(version, element);
  }
};

export const getProposalColor = (
  proposals: IProposal[],
  proposalOrId: ItemOrItemId<IProposal>,
): string => {
  const id = getId(proposalOrId);
  const index = proposals.findIndex((p) => p.id === id);
  return PROPOSAL_COLORS[index % PROPOSAL_COLORS.length] ?? '';
};

export const isEmptyProposal = (proposal: IProposal): boolean => {
  return Object.values(proposal.selections).filter(isDefined).length === 0;
};

export const getActiveProposal = (
  version: VersionOrProposals,
): IProposal | undefined =>
  getProposalsInVersion(version).find((p) => p.active);

/**
 * Get all proposals that are available to an element
 * @param proposals All proposals in version
 * @param path Path to element (from root to element)
 * @returns
 */
export const getAvailableProposals = (
  proposals: IProposal[] | undefined,
  path: OneOfParentElements[],
): IProposal[] => {
  if (!proposals) {
    return [];
  }
  let currentProposals = proposals;

  for (const parent of path) {
    const parentVersionId = getElementVersionId(parent);

    // All proposals are selecting the element
    if (!parentVersionId) {
      continue;
    }

    const parentProposals = getProposalsWithElementSelected(
      currentProposals,
      parent,
    );

    // We can abort if no proposals are selecting the parent
    if (parentProposals.length === 0) {
      return [];
    }

    // Exclude proposals that doesn't use the parent
    currentProposals = currentProposals.filter((p) =>
      parentProposals.includes(p),
    );
  }
  return currentProposals;
};

/**
 * Get the proposals that the element is included in.
 * If no version id, the element is included in all available proposals.
 * @param proposals
 * @param path All parents to the element (from root to element)
 * @param elementOrId
 */
export const getProposalsUsingElement = (
  proposals: IProposal[],
  path: OneOfParentElements[],
  element: OneOfElementListElements,
): IProposal[] => {
  if (!proposals.length) {
    return [];
  }
  const versionId = getElementVersionId(element);

  // No version ids or one or less versions means that the element is used by all available proposals
  if (!versionId) {
    return getAvailableProposals(proposals, path);
  }
  return getProposalsWithElementSelected(proposals, element);
};

/**
 * Get all proposals explicitly selecting the element.
 * I.E. id added in proposals.selections).
 * @param proposals
 * @param element
 * @returns
 */
export const getProposalsWithElementSelected = (
  proposals: IProposal[],
  element: OneOfElementListElements,
): IProposal[] => {
  const versionId = getElementVersionId(element);

  return versionId
    ? proposals.filter((p) => isSelectedByProposal(p, element))
    : [];
};

export const cleanupObsoleteElementVersionsInProposals = (
  version: IBuildingVersion,
): IBuildingVersion => {
  const proposals = getProposalsInVersion(version);
  const elements = flattenElements(version);
  const elementIds = elements.map(getId);
  const versionIds = getElementVersionIds(flattenElements(version));

  for (const proposal of proposals) {
    const { id, selections } = proposal;

    const obsoleteIds = Object.keys(selections).filter((versionId) => {
      const elementId = getSelectedElementId(proposal, versionId);
      return (
        !elementId || // No selection for this id (safe to remove key)
        !elementIds.includes(elementId) || // Element is removed
        !versionIds.includes(versionId) // Element version is removed
      );
    });

    if (obsoleteIds.length > 0) {
      version = updateProposals(version, {
        id,
        selections: omit(selections, ...obsoleteIds),
      });
    }
  }

  return version;
};

/**
 * Check if an element is active in a proposal
 * @param proposal
 * @param path
 * @param element
 * @returns
 */
export const isElementActiveInProposal = (
  proposal: IProposal,
  path: OneOfParentElements[],
  element: OneOfElementListElements,
): boolean => {
  // First check cases that can be resolved without checking parents
  if (isBuildingVersionElement(element)) {
    return true;
  }

  const versionId = getElementVersionId(element);

  if (isElement(element)) {
    // Disabled by eye icon
    if (element.isDeactivated) {
      return false;
    }

    // Element is a version but not selected in proposal
    if (versionId && !isSelectedByProposal(proposal, element)) {
      return false;
    }
  }

  // Check if some parent is deactivated
  const hasDeactivatedParent = path.some(
    (parent) => !isElementActiveInProposal(proposal, [], parent),
  );

  // No deactivated parents and if it's a version, it's selected in proposal
  return (
    !hasDeactivatedParent &&
    (!versionId || isSelectedByProposal(proposal, element))
  );
};

/**
 * Get all elements that are active in a proposal.
 * Will also remove all children of deactivated elements.
 * @param version
 * @param proposalOrId
 * @returns
 */
export const getElementsActiveInProposal = (
  version: IBuildingVersion,
  proposalOrId: ItemOrItemId<IProposal>,
): OneOfElements[] => {
  const proposal = getProposalById(version, proposalOrId);
  const elements: OneOfElements[] = [];

  forEachElement(version, (element, path) => {
    if (isElementActiveInProposal(proposal, path, element)) {
      elements.push(element);
    }
  });
  return elements;
};

/**
 * Create a new element version and select it in the active proposal
 * @param version
 * @param original
 * @returns
 */
export const addElementVersionAndUpdateProposalSelections = (
  version: IBuildingVersion,
  original: IElement,
): IBuildingVersion => {
  const hasVersion = isElementVersionElement(original);

  // Add the new element version to the parent and update the project
  version = addElementVersion(version, original);

  // Get new modified elements
  const modifiedSearch = getPathAndElementById(version, original.id, true);

  // Newly added element will be the last of the versions
  const newElement = required(
    last(getElementVersions(modifiedSearch.parent, modifiedSearch.element)),
  );

  // Make sure the original element is still selected if it was selected before. Else select the new element as default
  const defaultSelection = hasVersion
    ? newElement
    : getElementById(version, original.id, ElementKind.Element);

  version = addElementVersionToUnselectedProposals(version, defaultSelection);

  return updateElementActiveStateByActiveProposal(version, newElement);
};

/**
 * Get the elementId selected by the proposal
 * @param proposal
 * @param elementOrVersionId
 * @returns An element id if selected, otherwise undefined
 */
export const getSelectedElementId = (
  proposal: IProposal | undefined,
  elementOrVersionId: ElementOrVersionId,
): IElement['id'] | undefined => {
  const versionId = getElementVersionId(elementOrVersionId);
  return versionId ? proposal?.selections[versionId] : undefined;
};
