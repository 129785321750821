import { OptionsObject, useSnackbar } from 'notistack';
import { getProductRecordFromElement } from '../../../shared/helpers/product_helpers';
import { getRecipesUsedInElement } from '../../../shared/helpers/recipe_helpers';
import { ProductRecord } from '../../../shared/models/product.interface';
import {
  ProjectID,
  OneOfSelectableElements,
  OneOfChildElements,
} from '../../../shared/models/project.interface';
import { Recipe } from '../../../shared/models/recipe.interface';
import {
  getMainCategoryElement,
  isMainCategoryElement,
} from '../../../shared/templates/categories';
import { getProject, useAddElement } from '../store/project';
import { getRecipes } from '../store/recipe/recipe.hook';
import { getSelectedElement, getSelectedVersion } from '../store/ui';
import { useCallback } from 'react';
import { getProductsLookup } from '../store/product';
import { getElementName } from '../../../shared/helpers/element_helpers';
import { usePromiseSnackbar } from './snackbar.hook';
import {
  getClipboardObject,
  setClipboardObject,
} from '../../../shared/helpers/copy-paste.helpers';
import {
  getParentElement,
  isElement,
  isOneOfChildElements,
  isOneOfParentElements,
  isProductElement,
} from '../../../shared/helpers/recursive_element_helpers';
import {
  hasDefinedProperties,
  omit,
} from '../../../shared/helpers/object_helpers';
import { ElementCategoryID } from '../../../shared/models/element_categories.interface';
import { useAppVersion } from '../providers/ConfigProvider';
import { useImportElement } from './import.hook';
import { setElementExpanded } from '../hooks/expand-elements.hook';
import { isProductCategoryElement } from '../../../shared/helpers/element_category_helpers';
import {
  getElementVersionId,
  getElementVersions,
  isElementVersionElement,
} from '../../../shared/helpers/element-version.helpers';

interface ICopyPasteElement {
  projectId: ProjectID;
  recipes: Recipe[];
  products: ProductRecord;
  element: OneOfChildElements;
  appVersion: string;
}
const SNACKBAR_OPTIONS: OptionsObject = {
  variant: 'info',
  autoHideDuration: 2000,
  preventDuplicate: true,
} as const;

const SNACKBAR_OPTIONS_ERROR: OptionsObject = {
  ...SNACKBAR_OPTIONS,
  variant: 'error',
} as const;

export const useCopyElementToClipboard = () => {
  const promiseSnackbar = usePromiseSnackbar();
  const appVersion = useAppVersion();
  const isValidCopyPasteElement = useIsValidCopyPasteElement();

  return useCallback(
    async (
      element: OneOfSelectableElements | undefined = getSelectedElement(),
    ): Promise<ICopyPasteElement | undefined> => {
      const project = getProject();
      const productRecord = getProductsLookup();

      if (
        !element ||
        !isOneOfChildElements(element) ||
        isMainCategoryElement(element)
      ) {
        return;
      }

      const recipes = getRecipes();
      const filteredRecipes = getRecipesUsedInElement(element, recipes);
      const products = getProductRecordFromElement(
        element,
        productRecord,
        recipes,
      );
      const copyObject: ICopyPasteElement = {
        projectId: project.id,
        recipes: filteredRecipes,
        products,
        element,
        appVersion,
      };

      // Shouldn't happen but just in case
      if (!isValidCopyPasteElement(copyObject)) {
        return;
      }

      const successMessage = `Copied Element: ${getElementName(element, products)}`;

      await promiseSnackbar(setClipboardObject<ICopyPasteElement>(copyObject), {
        ...SNACKBAR_OPTIONS,
        successMessage,
      });

      return copyObject;
    },
    [appVersion, isValidCopyPasteElement, promiseSnackbar],
  );
};

export const usePasteElementFromClipboard = () => {
  const { enqueueSnackbar } = useSnackbar();
  const isValidCopyPasteElement = useIsValidCopyPasteElement();
  const addElement = useAddElement();
  const importElement = useImportElement();

  return useCallback(async (): Promise<
    ICopyPasteElement['element'] | undefined
  > => {
    const version = getSelectedVersion();
    const selectedElement = getSelectedElement();

    if (!version) {
      throw new Error('No version available');
    }

    // When no element is selected, paste to main category other
    const mainDefault = getMainCategoryElement(
      version,
      ElementCategoryID.MAIN_OTHER,
    );

    const target = isProductElement(selectedElement)
      ? getParentElement(version, selectedElement)
      : (selectedElement ?? mainDefault);

    // Nowhere to paste
    if (!target || !isOneOfParentElements(target)) {
      enqueueSnackbar('No paste target selected', SNACKBAR_OPTIONS_ERROR);
      return;
    }
    const data = await getClipboardObject<ICopyPasteElement>();

    // Make sure data is an element to paste
    if (!isValidCopyPasteElement(data)) {
      return;
    }
    const pasteAsSibling = shouldPasteAsSibling(target, data.element);
    const originalVersionId = getElementVersionId(data.element);
    let element = await importElement(
      data.element,
      data.recipes,
      data.products,
    );

    // Pasting element version on other version should result in a duplication of the element version if it has multiple versions
    if (isElementVersionElement(element)) {
      if (
        getElementVersions(target, element).length > 1 &&
        pasteAsSibling &&
        originalVersionId
      ) {
        element = {
          ...element,
          versionId: originalVersionId,
          isActiveVersion: false,
        };
      } else {
        element = omit(element, 'versionId', 'isActiveVersion');
      }
    }

    const successMessage = `Pasted Element: ${getElementName(element, getProductsLookup())}`;

    const response = await addElement(target, element, {
      snackbarOptions: {
        ...SNACKBAR_OPTIONS,
        successMessage,
      },
      isSibling: pasteAsSibling,
      placement: pasteAsSibling ? 'after' : 'first',
    });

    if (isElement(target)) {
      // Make sure parent is expanded
      setElementExpanded(target.id, !pasteAsSibling);
    }

    return response.element;
  }, [addElement, enqueueSnackbar, importElement, isValidCopyPasteElement]);
};

const useIsValidCopyPasteElement = () => {
  const appVersion = useAppVersion();

  return useCallback(
    (data?: ICopyPasteElement): data is ICopyPasteElement => {
      return (
        !!data &&
        hasDefinedProperties(
          data,
          'projectId',
          'recipes',
          'products',
          'element',
        ) &&
        data.appVersion === appVersion // Make sure the data is not from a previous release
      );
    },
    [appVersion],
  );
};

/**
 * Check if the element should be pasted as a sibling.
 * @param target
 * @param element
 * @returns
 */
const shouldPasteAsSibling = (
  target: OneOfSelectableElements,
  element: OneOfChildElements,
) => {
  if (isMainCategoryElement(target)) {
    return false;
  }
  if (isProductCategoryElement(target) || isProductElement(target)) {
    return true;
  }

  // If copy and paste an element
  if (element.id === target.id) {
    return true;
  }

  // If copy and paste an element version, it should be pasted as a sibling
  return getElementVersionId(element) === getElementVersionId(target);
};
