import React, { FC, useMemo } from 'react';
import { Box, Divider, List, ListItem } from '@mui/material';
import { isElement } from '../../../../../../shared/helpers/recursive_element_helpers';
import { IElement } from '../../../../../../shared/models/project.interface';
import ElementChild from './ElementChild';
import ElementChildAutoGenerated from './ElementChildAutoGenerated/ElementChildAutoGenerated';
import {
  getElementCategoryId,
  isGeneratedProductElement,
} from '../../../../../../shared/helpers/element_helpers';
import { useFlattenSortedElements } from '../../../../hooks/sort.hook';
import { isSystemCategory } from '../../../../../../shared/helpers/element_category_helpers';
import LayerPreview from '../../../../projects/EditProject/Settingspage/Layers/LayerPreview';
import { dividerStyles } from '../../../../style/constants';

interface ElementChildrenProps {
  element: IElement;
}

const ElementChildren: FC<ElementChildrenProps> = ({ element }) => {
  const sortedChildren = useFlattenSortedElements(element);
  const filteredChildren = useMemo(
    () =>
      sortedChildren.filter((child) => {
        const isElementAndNotSelf = isElement(child) && child.id !== element.id;

        const isGeneratedProductElementAndDirectChild =
          isGeneratedProductElement(child) &&
          element.elements.some((el) => el.id === child.id);

        return (
          isElementAndNotSelf ||
          (isGeneratedProductElementAndDirectChild && child.count.resolved > 0)
        );
      }),
    [sortedChildren, element],
  );

  if (!filteredChildren.length) {
    return null;
  }

  const systemCategoryParent =
    isElement(parent) && isSystemCategory(getElementCategoryId(parent))
      ? parent
      : undefined;

  return (
    <Box>
      <LayerPreview
        element={systemCategoryParent ?? element}
        highlight={systemCategoryParent ? element : undefined}
      />
      <Divider sx={dividerStyles} />
      <List>
        {filteredChildren.map((child) => {
          return (
            <ListItem
              key={child.id}
              disableGutters
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                lineHeight: 2,
                padding: 2,
              }}
            >
              {isElement(child) && (
                <ElementChild root={element} element={child} />
              )}
              {isGeneratedProductElement(child) && (
                <ElementChildAutoGenerated
                  parent={element}
                  productElement={child}
                />
              )}
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
};

export default ElementChildren;
