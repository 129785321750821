import { OTHER_PRODUCT_ID } from '../constants';
import { isElementCategoryID } from '../helpers/element_category_helpers';
import { getMissingProperties } from '../helpers/object_helpers';
import {
  getProductSourceFromId,
  isCustomProduct,
  isBoverketProduct,
  isOekobaudatProduct,
  isNodonProduct,
  getOriginalProductId,
} from '../helpers/product_helpers';
import { makeSentence } from '../helpers/string_helpers';
import {
  Product,
  IFactoryProduct,
  REQUIRED_PRODUCT_PROPERTIES,
  CUSTOM_ID_PREFIX,
} from '../models/product.interface';
import { quantityUnits } from '../models/unit.interface';
import { throwValidationErrors, ValidationTypes } from './validation.helpers';

/**
 * Validate product
 * @param product
 * @param verbose
 * @returns string for error or true for valid
 */
export const isValidProduct = (
  product: Product | IFactoryProduct | undefined,
): ValidationTypes => {
  if (!product) {
    return 'Product is not defined';
  }

  const missingProperties = getMissingProperties(
    product,
    ...REQUIRED_PRODUCT_PROPERTIES,
  );

  if (missingProperties.length) {
    return (
      'Product is missing properties: ' + makeSentence(...missingProperties)
    );
  }
  const {
    id,
    name,
    source,
    categories,
    organizations,
    unit,
    generic_id,
    category_property_value_record,
    category_id,
  } = product as Product;
  const idSource = getProductSourceFromId(id);

  if (generic_id) {
    if (!isCustomProduct(id)) {
      return 'Product has a generic_id but is not a custom product';
    }
    if (
      !isBoverketProduct(generic_id) &&
      !isNodonProduct(generic_id) &&
      generic_id !== OTHER_PRODUCT_ID
    ) {
      return 'Only boverket & nodon products are allowed as a generic_id currently';
    }
  }

  if (Object.keys(category_property_value_record).length && !category_id) {
    return 'Product has category_property_value_record but no category_id';
  }

  if (category_id && !isElementCategoryID(category_id)) {
    return 'Product has invalid category_id';
  }

  if (!name) {
    return 'Product has no name';
  }

  if (idSource !== source) {
    return 'Product id and source is not matching';
  }

  if (!quantityUnits.includes(unit)) {
    return 'Product has invalid unit ' + unit;
  }

  // Only for custom products
  if (source === 'custom') {
    if (!organizations?.length) {
      return 'Custom product has no organizations';
    }
    if (categories.Custom !== true) {
      return 'Custom product has no custom category';
    }
  }

  return isValidProductId(id);
};

const isValidProductId = (id: Product['id']): boolean | string => {
  if (
    !isOekobaudatProduct(id) &&
    !isBoverketProduct(id) &&
    !isCustomProduct(id) &&
    !isNodonProduct(id)
  ) {
    return 'Invalid product id prefix';
  }
  const suffix = getOriginalProductId(id).replace(CUSTOM_ID_PREFIX, '');
  if (suffix.length < 7) {
    return 'Invalid product id suffix';
  }
  return true;
};

export const validateProduct = (
  product: Product | IFactoryProduct | undefined,
): Product => {
  throwValidationErrors(isValidProduct(product), 'ProductError');
  return product as Product;
};
