import React, { useCallback, useMemo, useState } from 'react';
import { Edit, KeyboardArrowRight, Add } from '@mui/icons-material';
import ClearIcon from '@mui/icons-material/Clear';
import { MenuItem, Divider, ListItemIcon, SxProps } from '@mui/material';
import {
  Product,
  ProductID,
} from '../../../../../../../shared/models/product.interface';
import {
  setEPDIdsAppliedToGenericProduct,
  useEPDIdsAppliedToGenericProduct,
} from '../../../../../hooks/product-switch.hook';
import { useUpdateProductInVersion } from '../../../../../hooks/useProducts';
import { useUpdateProduct } from '../../../../../store/product';
import { useBooleanState } from '../../../../../hooks/hooks';
import { NodonTheme } from '../../../../../style';
import { TEMPLATE_ORGANIZATION } from '../../../../../../../shared/constants';
import {
  KebabButtonMenu,
  IMenuItemData,
} from '../../../../SelectMenu/KebabButtonMenu';
import { KebabButtonProps } from '../../../../SelectMenu/KebabButton';

interface EPDMenuItemsProps {
  variant: 'button' | 'select';
  epds: Product[];

  /** Generated product from PC element */
  genericProductId: ProductID;

  /** Custom product applied to the generic product */
  selectedEpdId: string | undefined;
  setSelectedEpdId: (epdId: string) => void;

  /** Id of the menu item that the kebab sub menu has been opened from. */
  epdId: string;
  setEpdId: (epdId: string) => void;

  selectEPD: (epdId: string) => Promise<void>;
  clearEPD: () => Promise<void>;
  handleEPD: (id?: ProductID) => Promise<void>;
}

export const useEPDMenuItems = ({
  variant,
  epds,
  genericProductId,
  selectedEpdId,
  epdId,
  setSelectedEpdId,
  setEpdId,
  selectEPD,
  clearEPD,
  handleEPD,
}: EPDMenuItemsProps) => {
  const updateProduct = useUpdateProduct();
  const updateProductInVersion = useUpdateProductInVersion();

  const epdIdsAppliedToGenericProduct =
    useEPDIdsAppliedToGenericProduct(genericProductId);

  const [epdHoverId, setEpdHoverId] = useState('');
  const [kebabHover, startKebabHover, endKebabHover] = useBooleanState(false);

  const removeEPDMapping = useCallback(
    (id: ProductID) => {
      // remove EPD mapping from localstorage
      const filteredIds =
        epdIdsAppliedToGenericProduct?.filter((epdId) => epdId !== id) ?? [];
      setEPDIdsAppliedToGenericProduct(genericProductId, filteredIds);

      // remove EPD mapping from element
      if (id === selectedEpdId) clearEPD();

      const epd = epds.find((epd) => epd.id === id);
      if (!epd?.generic_id) return;

      const unmappedEPD = { ...epd, generic_id: undefined };

      // remove EPD mapping from product in version.products
      updateProductInVersion(unmappedEPD);

      // remove EPD mapping from product in database
      if (epd.generic_id === genericProductId) {
        updateProduct(unmappedEPD);
      }
    },
    [
      epdIdsAppliedToGenericProduct,
      genericProductId,
      selectedEpdId,
      clearEPD,
      epds,
      updateProductInVersion,
      updateProduct,
    ],
  );

  const getMenuClickFn = useCallback(
    (id: string) => () =>
      variant === 'button' && !epdId ? selectEPD(id) : undefined,
    [epdId, selectEPD, variant],
  );

  const handleItemMouseEnter = useCallback(
    (id: string) => () => setEpdHoverId(id),
    [],
  );

  const handleItemMouseLeave = useCallback(() => setEpdHoverId(''), []);

  const handleSetKebabItemId = useCallback(
    (id: string) => () => setEpdId(id),
    [setEpdId],
  );

  const handleKebabClose = useCallback(() => {
    setEpdId('');
    setEpdHoverId('');
  }, [setEpdId]);

  const getKebabItems = useCallback(
    (id: string, organizations?: string[]): IMenuItemData[] => {
      const disabled = organizations?.includes(TEMPLATE_ORGANIZATION);

      return [
        {
          id: 'epd-menu.edit',
          label: 'Edit EPD',
          icon: <Edit fontSize="small" />,
          disabled,
          tooltipTitle: disabled ? 'Cannot edit template EPD' : '',
          onClick: () => {
            handleEPD(id);
            setEpdId('');
          },
        },
        {
          id: 'epd-menu.remove',
          label: 'Remove from list',
          icon: <ClearIcon fontSize="small" />,
          onClick: () => {
            if (id === selectedEpdId) {
              setSelectedEpdId('');
            }
            removeEPDMapping(id);
            setEpdId('');
          },
        },
      ];
    },
    [handleEPD, setEpdId, selectedEpdId, removeEPDMapping, setSelectedEpdId],
  );

  const kebabIconProps = useMemo<Partial<KebabButtonProps>>(
    () => ({
      svgProps: {
        sx: {
          color: NodonTheme.palette.neutral[kebabHover ? 'dark' : 'light'],
        },
      },
    }),
    [kebabHover],
  );

  return useMemo(() => {
    return [
      <MenuItem
        disableRipple
        value="none"
        key="none"
        onClick={getMenuClickFn('none')}
      >
        None
      </MenuItem>,

      <Divider key="divider1" />,

      ...epds.map(({ id, name, organizations }) => (
        <MenuItem
          disableRipple
          key={id}
          value={id}
          sx={epdItemStyles}
          onClick={getMenuClickFn(id)}
          onMouseEnter={handleItemMouseEnter(id)}
          onMouseLeave={handleItemMouseLeave}
        >
          {name}

          <KebabButtonMenu
            id={id}
            m={0}
            hidden={epdHoverId !== id}
            kebabButtonProps={kebabIconProps}
            items={getKebabItems(id, organizations)}
            onMouseEnter={startKebabHover}
            onMouseLeave={endKebabHover}
            onOpen={handleSetKebabItemId(id)}
            onClose={handleKebabClose}
          />
        </MenuItem>
      )),

      !!epds.length && <Divider key="divider2" />,

      <MenuItem
        disableRipple
        key="other"
        value="other"
        onClick={getMenuClickFn('other')}
        sx={otherItemStyles}
      >
        Other EPD
        <ListItemIcon sx={{ justifyContent: 'center' }}>
          <KeyboardArrowRight fontSize="small" />
        </ListItemIcon>
      </MenuItem>,

      <MenuItem
        disableRipple
        value="new"
        key="new"
        onClick={getMenuClickFn('new')}
      >
        <ListItemIcon>
          <Add fontSize="small" />
        </ListItemIcon>
        New EPD
      </MenuItem>,
    ];
  }, [
    endKebabHover,
    epdHoverId,
    epds,
    getKebabItems,
    getMenuClickFn,
    handleItemMouseEnter,
    handleItemMouseLeave,
    handleKebabClose,
    handleSetKebabItemId,
    kebabIconProps,
    startKebabHover,
  ]);
};

const epdItemStyles: SxProps = {
  display: 'flex',
  justifyContent: 'space-between',
};

const otherItemStyles: SxProps = {
  display: 'flex',
  justifyContent: 'space-between',
  '& .MuiListItemIcon-root': { minWidth: 26 },
};
