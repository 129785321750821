import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  IconButton,
  OutlinedTextFieldProps,
  Tooltip,
  Typography,
} from '@mui/material';
import { OneOfPropertyElements } from '../../../../../../shared/models/project.interface';
import { Delete } from '@mui/icons-material';
import InlineTextField from '../../../InlineTextField';

import { IElementProperty } from '../../../../../../shared/models/element_property.interface';
import { useIsAddedProperty, useUIState } from '../../../../store/ui';
import { useElementPropertiesUtils } from '../../../../hooks/element-properties.hook';
import { useBooleanState, useTrigger } from '../../../../hooks/hooks';
import {
  getPropertyDisplayName,
  isElementExpressionProperty,
  isElementSelectProperty,
  isElementSwitchProperty,
  isFullyEditableProperty,
  isIconElementProperty,
  getNameTooltip,
} from '../../../../../../shared/helpers/element_property_helpers';
import { makeStyles } from 'tss-react/mui';
import { isElementQuantityRepeatingChildName } from '../../../../../../shared/helpers/element_quantity_helpers';
import ElementPropertySelect from './ElementPropertySelect';
import ElementPropertySwitchInput from './ElementPropertySwitch';
import ElementPropertyExpression from './ElementPropertyExpression';
import { ElementQuantityName } from '../../../../../../shared/models/element_quantities.interface';
import { quantityIcons } from '../../../Icon';

interface ElementPropertyProps {
  property: IElementProperty;
  element: OneOfPropertyElements;
  hideDeleteColumn?: boolean;
  hideNameColumn?: boolean;
}
const ElementProperty: FC<ElementPropertyProps> = ({
  property,
  element,
  hideDeleteColumn,
  hideNameColumn,
}) => {
  const { cx, classes } = useStyles();
  const { id } = property;

  const { setAddedPropertyId } = useUIState('setAddedPropertyId');
  const { removeProperty, updateProperty } = useElementPropertiesUtils(element);

  const deleteProperty = useTrigger(removeProperty, id);
  const autoFocus = useIsAddedProperty(property);
  const isEditableProperty = isFullyEditableProperty(property);

  const [isEditingName, beginEditingName, endEditingName] =
    useBooleanState(autoFocus);

  const [nameIsInvalid, setNameIsInvalid] = useState(false);

  const [inputErrorMessage, setInputErrorMessage] = useState<
    string | undefined
  >();

  const errorMessage =
    nameIsInvalid && isEditableProperty ? 'Name is invalid' : inputErrorMessage;

  const isRepeatingChild = isElementQuantityRepeatingChildName(property.name);

  const isIconProperty = isIconElementProperty(property);

  const savePropertyName = useCallback(
    async (name: string) => {
      if (!nameIsInvalid || isEditableProperty) {
        if (!nameIsInvalid) {
          await updateProperty({ ...property, name });
        }
        endEditingName();
      }
    },
    [
      endEditingName,
      isEditableProperty,
      nameIsInvalid,
      property,
      updateProperty,
    ],
  );

  useEffect(() => {
    if (autoFocus) {
      beginEditingName();
    }
  }, [autoFocus, beginEditingName]);

  const textFieldProps = useMemo<Partial<OutlinedTextFieldProps>>(
    () => ({
      autoFocus,
      onFocus: (e) => {
        if (autoFocus) {
          e.currentTarget.select();
        }
      },
      onBlur: () => {
        if (autoFocus) {
          setAddedPropertyId(undefined);
        }
      },
    }),
    [autoFocus, setAddedPropertyId],
  );

  const input = useMemo(() => {
    /* 
    If the name is being edited, 
    make it readonly in case the value input is clicked before the name has been saved.
    */
    const editableProperty = isEditingName
      ? { ...property, readonly: true }
      : property;

    if (isElementExpressionProperty(editableProperty)) {
      return (
        <ElementPropertyExpression
          key={editableProperty.id}
          property={editableProperty}
          element={element}
          onErrorMessageChange={setInputErrorMessage}
        />
      );
    } else if (isElementSelectProperty(editableProperty)) {
      return (
        <ElementPropertySelect
          key={property.id}
          property={editableProperty}
          element={element}
        />
      );
    } else if (isElementSwitchProperty(editableProperty)) {
      return (
        <ElementPropertySwitchInput
          key={editableProperty.id}
          property={editableProperty}
          element={element}
        />
      );
    }
  }, [element, isEditingName, property]);

  return (
    <>
      <Tooltip
        title={isIconProperty && getPropertyDisplayName(property)}
        placement="left"
        disableInteractive
      >
        <Box
          className={cx(
            classes.container,
            hideDeleteColumn ? classes.hideDeleteColumn : null,
            hideNameColumn ? classes.hideNameColumn : null,
            isRepeatingChild ? classes.child : null,
            isIconProperty ? classes.showIcon : null,
          )}
        >
          {hideNameColumn || isIconProperty ? (
            // ICON LABEL
            <Box display="flex">
              {quantityIcons[property.name as ElementQuantityName]}
            </Box>
          ) : (
            // NAME LABEL
            <Tooltip
              title={getNameTooltip(property)}
              placement="left"
              disableInteractive
            >
              <Box flexGrow={1}>
                <InlineTextField
                  value={getPropertyDisplayName(property)}
                  variant="body2"
                  editing={isEditingName}
                  disabled={!isEditableProperty}
                  onClick={beginEditingName}
                  onCancel={endEditingName}
                  onSave={savePropertyName}
                  error={!!nameIsInvalid}
                  setNameIsInvalid={setNameIsInvalid}
                  displayBorder={false}
                  textFieldProps={textFieldProps}
                />
              </Box>
            </Tooltip>
          )}

          {/* VALUE INPUT */}
          <Box
            display="flex"
            justifyContent={isIconProperty ? 'flex-start' : 'flex-end'}
          >
            {input}
          </Box>

          {/* DELETE ICON */}
          {!hideDeleteColumn && (
            <Box className={classes.deleteIcon}>
              {isEditableProperty && (
                <Tooltip title="Delete property">
                  <IconButton size="small" onClick={deleteProperty}>
                    <Delete fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          )}
        </Box>
      </Tooltip>

      {/* ERROR MESSAGE */}
      {errorMessage && (
        <Typography
          className={classes.errorMessage}
          color="error"
          variant="caption"
        >
          {errorMessage}
        </Typography>
      )}
    </>
  );
};

export const useStyles = makeStyles<void, 'deleteIcon'>()(
  ({ spacing }, _params, classes) => ({
    container: {
      // display: 'grid',
      // gridAutoFlow: 'column',
      // gridTemplateColumns: 'auto auto 30px',

      // wrap: 'nowrap',
      // alignItems: 'center',
      // justifyContent: 'space-between',
      // gap: spacing(1),

      display: 'flex',

      minWidth: 0,
      paddingTop: spacing(1),

      [`&:hover .${classes.deleteIcon}`]: {
        opacity: 1,
      },
    },
    hideDeleteColumn: {
      // gridTemplateColumns: 'auto',
    },
    hideNameColumn: {
      // display: 'block',
    },
    showIcon: {
      width: '50%',
      gridTemplateColumns: '20px auto',
      justifyContent: 'start',
      alignItems: 'center',
      gap: spacing(2),
    },
    child: {
      paddingLeft: spacing(3),
      backgroundColor: 'rgba(0, 0, 0, 0.05)',

      '&:last-of-type': {
        borderRadius: '5px',
      },
    },
    input: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    deleteIcon: {
      opacity: 0,
      transition: 'opacity 0.2s ease',
    },
    errorMessage: {
      gridRow: 2,
      gridColumn: '1 / -1',
    },
  }),
);

export default ElementProperty;
