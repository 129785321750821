import React from 'react';
import { Box } from '@mui/material';
import ElementRecipe from './ElementRecipe';
import { IElement } from '../../../../../shared/models/project.interface';
import { useResults } from '../../../hooks/useResults';
import ElementResult from './ElementResults';
import ElementChildren from './ElementChildren/ElementChildren';
import ElementQuantities from './ElementQuantities';
import ElementClose from './ElementClose';
import ElementComments from './ElementComments';
import ResultPanel from './ElementChildren/ResultPanel';
import ElementCategoryMenu from './ElementCategory/ElementCategoryMenu';

interface ElementProps {
  selectedElement: IElement;
}

const Element: React.FC<ElementProps> = ({ selectedElement }) => {
  const results = useResults();

  if (!selectedElement) {
    return null;
  }

  return (
    <Box p={2} mb={25}>
      <ElementClose />

      <ElementCategoryMenu element={selectedElement} pb={2} />

      <ElementQuantities element={selectedElement} />

      <ElementRecipe element={selectedElement} />

      <ElementResult results={results}></ElementResult>

      <ElementChildren element={selectedElement} />

      <ResultPanel elementId={selectedElement.id} factor="co2e_total" />
      <ResultPanel elementId={selectedElement.id} factor="sek_A1-A3" />

      <ElementComments elementId={selectedElement.id} />
    </Box>
  );
};

export default Element;
