import React, { useMemo } from 'react';
import { DeleteForever, Edit } from '@mui/icons-material';
import { useDeleteFolder } from '../../../store/folder/folder.hook';
import { projectActionMenuIconStyles } from '../../../style/constants';
import { KebabButtonMenu } from '../../SelectMenu/KebabButtonMenu';
import { ProjectListItemKebabMenuInput } from '../ListItems/ProjectListItem';

const ProjectFolderKebabMenu: React.FC<ProjectListItemKebabMenuInput> = ({
  item: folder,
  anchor,
  onOpen,
  onClose,
  onEditName,
}) => {
  const { id } = folder;
  const deleteFolder = useDeleteFolder();

  const items = useMemo(
    () => [
      {
        id: 'rename',
        label: 'Rename',
        icon: <Edit {...projectActionMenuIconStyles} />,
        onClick: onEditName,
      },
      {
        id: 'delete',
        label: 'Delete',
        icon: <DeleteForever {...projectActionMenuIconStyles} />,
        onClick: () => deleteFolder(id),
      },
    ],
    [deleteFolder, id, onEditName],
  );

  return (
    <KebabButtonMenu
      id={id}
      items={items}
      anchor={anchor}
      onOpen={onOpen}
      onClose={onClose}
    />
  );
};

export default ProjectFolderKebabMenu;
