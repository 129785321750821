import React, { useMemo } from 'react';
import { Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import {
  CircleOutlined,
  CropSquareSharp,
  Folder,
  FolderOpen,
  InsertDriveFile,
  KeyboardArrowDown,
  KeyboardArrowRight,
} from '@mui/icons-material';
import {
  ElementKind,
  OneOfListElements,
  OneOfProjectListElements,
} from '../../../../../shared/models/project.interface';
import {
  toggleElementExpanded,
  useIsElementExpanded,
} from '../../../hooks/expand-elements.hook';
import { isElementWithGeneratedChildren } from '../../../../../shared/helpers/element_helpers';
import { useMouseEventCallback } from '../../../hooks/events.hook';
import { Product } from '../../../../../shared/models/product.interface';
import {
  getElementKind,
  isElement,
} from '../../../../../shared/helpers/recursive_element_helpers';
import { isProduct } from '../../../../../shared/helpers/product_helpers';
import {
  isProjectFolder,
  isProjectInfoOrFolder,
} from '../../../../../shared/helpers/project-folder.helpers';
import { flattenTree } from '../../../../../shared/helpers/tree.helpers';
import { useUIState } from '../../../store/ui';

interface ExpandIconData {
  element: OneOfListElements | Product;
  disabled?: boolean;
}

const ExpandButton: React.FC<ExpandIconData> = ({ element, disabled }) => {
  const { selectedProjectFolderId, setSelectedProjectFolderId } = useUIState(
    'selectedProjectFolderId',
    'setSelectedProjectFolderId',
  );
  const { classes } = useStyles();
  const kind = getElementKind(element);
  const expanded = useIsElementExpanded(element);
  const isProductCategoryElement =
    isElement(element) && isElementWithGeneratedChildren(element);

  const handleClick = useMouseEventCallback(
    () => {
      /* make sure to deselect any children if the parent is a folder that's collapsed */
      if (isProjectFolder(element)) {
        const ids = flattenTree<OneOfProjectListElements>(element, 'elements')
          .filter((e) => isProjectFolder(e) && e.id !== element.id)
          .map((f) => f.id);

        if (selectedProjectFolderId && ids.includes(selectedProjectFolderId)) {
          setSelectedProjectFolderId(undefined);
        }
      }

      toggleElementExpanded(element, isProjectInfoOrFolder(element));
    },
    { stopPropagation: true },
  );

  const color = disabled ? 'disabled' : 'inherit';
  const folderIcon = useMemo(
    () =>
      expanded ? (
        <FolderOpen fontSize="small" color={color} />
      ) : (
        <Folder fontSize="small" color={color} />
      ),
    [color, expanded],
  );

  const arrowIcon = useMemo(
    () =>
      expanded ? (
        <KeyboardArrowDown fontSize="small" color={color} />
      ) : (
        <KeyboardArrowRight fontSize="small" color={color} />
      ),
    [color, expanded],
  );

  const icon = useMemo(() => {
    if (isProduct(element)) {
      return arrowIcon;
    }

    switch (kind) {
      case ElementKind.ProjectInfo:
        return <InsertDriveFile fontSize="small" />;
      case ElementKind.ProjectFolder:
        return folderIcon;
      case ElementKind.Product:
        return (
          <CircleOutlined fontSize="small" sx={rotationStyle} color={color} />
        );
      case ElementKind.Element:
        return !isProductCategoryElement ? (
          arrowIcon
        ) : (
          <CropSquareSharp fontSize="small" sx={rotationStyle} color={color} />
        );
    }
  }, [element, kind, arrowIcon, folderIcon, isProductCategoryElement, color]);

  return (
    <Button
      onClick={handleClick}
      disabled={disabled}
      className={classes.icon}
      color="neutral"
    >
      {icon}
    </Button>
  );
};

const rotationStyle = { transform: 'rotate(45deg) scale(0.5)' } as const;

const useStyles = makeStyles()(({ spacing, palette }) => ({
  icon: {
    color: palette.grey[500],
    minWidth: spacing(10),
    padding: 10,
  },
}));

export default ExpandButton;
