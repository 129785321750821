import { pick } from 'lodash';
import {
  Results,
  ConversionFactors,
  emptyConversionFactors,
  PartialConversionFactorQuantityRecord,
  QuantityUnit,
} from '../models/unit.interface';
import {
  getKeys,
  hasSomeDefinedProperties,
  omitUndefined,
} from './object_helpers';
import { ArrayOrRecord } from '../models/type_helpers.interface';
import { isDefined } from './array_helpers';
import { Product } from '../models/product.interface';
import {
  canConvertConversionFactors,
  convertConversionFactors,
} from './conversion_helpers';
import { logReturn } from './debug.helpers';

const applyCO2eTotal = (factors: Results): Results => {
  return { ...factors, co2e_total: getCO2eTotalFromConversionFactors(factors) };
};

export const createConversionFactors = (
  defaults: Partial<ConversionFactors> | number = 0,
): Results => {
  return applyCO2eTotal({
    ...emptyConversionFactors,
    ...(typeof defaults === 'number'
      ? { 'co2e_A1-A3': defaults }
      : omitUndefined(defaults)),
  });
};

/**
 * Merge conversion factors, scaling the factors to a common unit if possible.
 * @param base Default values that can be overriden. Will be scaled to match the overrides unit.
 * @param overrides Will override base values. Note that base values will scale to match the overrides unit.
 * @returns
 */
export const mergeConversionFactors = (
  base: ConversionFactors,
  overrides: Partial<ConversionFactors>,
): ConversionFactors => {
  overrides = omitUndefined(overrides);
  return logReturn(
    createConversionFactors({
      ...convertConversionFactors(base, overrides),
      ...overrides,
    }),
    'mergeConversionFactors',
  );
};

/**
 * Get the fallback conversion factors to use if the user input is not complete.
 * @param genericFactors
 * @param userInputFactors
 * @param selectedUnit
 * @returns
 */
export const getFallbackConversionFactors = (
  genericFactors: ConversionFactors | undefined,
  userInputFactors: ConversionFactors,
  selectedUnit: QuantityUnit,
): ConversionFactors => {
  const fallback = omitUndefined(genericFactors ?? {});
  const converted = canConvertConversionFactors(fallback, selectedUnit)
    ? convertConversionFactors(fallback, selectedUnit)
    : fallback;

  return converted;
};

/**
 * Sum all CO2e factors and return the total.
 * @param factors
 * @returns
 */
export const getCO2eTotalFromConversionFactors = (
  factors: ConversionFactors,
): number => {
  return Object.values(
    pick(factors, 'co2e_A1-A3', 'co2e_A4', 'co2e_A5'),
  ).reduce((sum, v) => sum + (v || 0), 0);
};

export const getCO2eTotalFromPartialConversionFactors = (
  factors: ConversionFactors,
): number | undefined => {
  return hasSomeDefinedProperties(factors, 'co2e_A1-A3', 'co2e_A4', 'co2e_A5')
    ? getCO2eTotalFromConversionFactors(factors)
    : undefined;
};

/**
 * Get summed conversion factors from a record of ConversionFactors
 * @param record
 * @returns
 */
export const sumConversionFactorRecord = (
  record: ArrayOrRecord<ConversionFactors>,
): ReturnType<typeof sumConversionFactors> =>
  sumConversionFactors(...Object.values(record));

/**
 * Sum all CO2e factors and return the total.
 * @param factors
 * @returns
 */
export const sumConversionFactors = (
  ...factors: (ConversionFactors | undefined)[]
): Results => {
  const conversionFactors: Results = {
    ...emptyConversionFactors,
  };
  factors.filter(isDefined).forEach((f) => {
    getKeys(f).forEach((key) => {
      conversionFactors[key] = (conversionFactors[key] || 0) + (f[key] || 0);
    });
  });
  return conversionFactors;
};

export const getEPDConversionFactor = (
  { id, generic_id }: Product,
  factors: PartialConversionFactorQuantityRecord,
  key: keyof Results,
): number => {
  const epdFactors = factors[id];
  const fallbacks = generic_id ? factors[generic_id] : undefined;

  return epdFactors?.[key] ?? fallbacks?.[key] ?? 0;
};
