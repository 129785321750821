/**
 * Wrap the return value of a function to log the return value
 * @param values
 * @returns
 */
export const logReturn = <T, K = unknown>(...values: [T, ...K[]]): T => {
  const [returnValue, ...otherValues] = values;
  console.info(...otherValues, returnValue);
  return returnValue;
};
